import React from "react";
import {
  Typography,
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const JsonRenderer = ({ data, level = 0, parentKey = "Results" }) => {
  const isUrl = (str) => {
    try {
      new URL(str);
    } catch (_) {
      return false;
    }
    return true;
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const getResultType = (data) => {
    if (data.output && data.output.image_url) {
      return "image";
    }
    return null;
  };

  const renderJson = (data, level = 0, parentKey = "Results") => {
    if (level === 0 && typeof data === "object" && data !== null) {
      //Special case for top level object: determine if we want to use special rendering
      var type = getResultType(data);
      if (type === "image") {
        return renderJson(data.output.image_url, level, "image_url");
      }
    }
    if (typeof data === "object" && data !== null) {
      if (parentKey === "image_url" && data.data && Array.isArray(data.data)) {
        return (
          <Box>
            <Typography variant="h6">{parentKey}</Typography>
            <Grid container spacing={2}>
              {data.data.map((imageObj, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <img
                    src={imageObj.url}
                    alt={imageObj.revised_prompt || `Image ${index + 1}`}
                    width="1024"
                    height="1024"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  {imageObj.revised_prompt && (
                    <Typography variant="caption">
                      {imageObj.revised_prompt}
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        );
      }

      return (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{parentKey}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box component="ul" sx={{ paddingLeft: level * 2 }}>
              {Object.entries(data).map(([key, value]) => (
                <Box component="li" key={key}>
                  {renderJson(value, level + 1, key)}
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    } else if (typeof data === "string" && isJsonString(data)) {
      return renderJson(JSON.parse(data), level, parentKey);
    } else if (typeof data === "string" && isUrl(data)) {
      return (
        <Typography component="span">
          <a href={data} target="_blank" rel="noopener noreferrer">
            {data}
          </a>
        </Typography>
      );
    } else {
      return (
        <Typography component="span">
          {parentKey}: {String(data)}
        </Typography>
      );
    }
  };

  return <>{renderJson(data, level, parentKey)}</>;
};

export default JsonRenderer;
