import React, { memo } from "react";
import { Handle, Position } from "@xyflow/react";
import { Input } from "@mui/material";

export default memo(({ data, isConnectable }) => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div>State Node</div>
      <Input
        className="nodrag"
        type="text"
        onChange={data.onChange}
        defaultValue={data.name}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="a"
        style={{ background: "#555" }}
        isConnectable={isConnectable}
      />
    </>
  );
});
