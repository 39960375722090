import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import { getAwsUrl } from "./EnvHelper";
import JsonRenderer from "./JsonRenderer";
import { useAuth0 } from "@auth0/auth0-react";

function Metrics() {
  const [userId, setUserId] = useState("");
  const [startDate, setStartDate] = useState(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1))
      .toISOString()
      .split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [userUsageData, setUserUsageData] = useState(null);
  const [aggregatedUsageData, setAggregatedUsageData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { getAccessTokenSilently } = useAuth0();

  const fetchUserUsageData = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      const url =
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + `/usage/usage/${userId}`;
      const response = await fetch(
        url + `?startDate=${startDate}&endDate=${endDate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setUserUsageData(data);
        setError("");
      } else {
        setError(data.error);
        setUserUsageData(null);
      }
    } catch (error) {
      setError(error.toString());
      setUserUsageData(null);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchAggregatedUsageData = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "read:current_user email profile",
          },
        });
        const url =
          getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/usage/usage";
        const response = await fetch(
          url + `?startDate=${startDate}&endDate=${endDate}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          setAggregatedUsageData(data);
          setError("");
        } else {
          setError(data.error);
          setAggregatedUsageData(null);
        }
      } catch (error) {
        setError(error.toString());
        setAggregatedUsageData(null);
      }
      setIsLoading(false);
    };
    fetchAggregatedUsageData();
  }, [startDate, endDate, getAccessTokenSilently]);

  const handleUserIdChange = (event) => {
    setUserId(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleFetchUserUsage = (event) => {
    event.preventDefault();
    fetchUserUsageData();
  };

  return (
    <Container className="Metrics">
      <form onSubmit={handleFetchUserUsage}>
        <div>
          <TextField
            label="User ID"
            name="userId"
            id="userId"
            value={userId}
            onChange={handleUserIdChange}
            fullWidth
          />
        </div>
        <div>
          <TextField
            label="Start Date"
            name="startDate"
            id="startDate"
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            fullWidth
          />
        </div>
        <div>
          <TextField
            label="End Date"
            name="endDate"
            id="endDate"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            fullWidth
          />
        </div>
        <div>
          <Button type="submit" disabled={isLoading}>
            Fetch User Usage
          </Button>
        </div>
      </form>
      <div>
        {isLoading && (
          <div>
            <CircularProgress />
            <Typography>Loading</Typography>
          </div>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </div>
      <Grid container spacing={2}>
        {userUsageData && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">User Usage Data</Typography>
                <JsonRenderer data={userUsageData} />
              </CardContent>
            </Card>
          </Grid>
        )}
        {aggregatedUsageData && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Aggregated Usage Data</Typography>
                <JsonRenderer data={aggregatedUsageData} />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default Metrics;
