import React from "react";
import FlowDiagram from "./FlowDiagram";
import { Container } from "@mui/material";
import "@xyflow/react/dist/style.css";

function DragonFlowsVisual() {
  const flowJson = {
    name: "CharacterPortrait",
    initial_state: "enhance_image_prompt",
    states: [
      {
        name: "enhance_image_prompt",
        transitions: [
          {
            target_state: "image_from_prompt",
          },
        ],
        actions: [
          {
            action_type: "prompt",
            prompt:
              "Please transform the given character description into a detailed and cohesive prompt for generating a Fantasy RPG character portrait using digital watercolor. Ensure the final prompt is highly detailed, realistic, and suitable for creating a movie still for marketing materials. The character should be facing the camera against a simple background. {{input}}",
            output_key: "image_prompt",
          },
        ],
      },
      {
        name: "image_from_prompt",
        transitions: [],
        actions: [
          {
            action_type: "image",
            prompt: "{{image_prompt}}",
            output_key: "image_url",
            action_name: "image_from_prompt",
          },
        ],
      },
    ],
  };

  return (
    <Container className="DragonFlowsVisual">
      <div style={{ height: "100vh", width: "100vw" }}>
        <FlowDiagram flowJson={flowJson} />
      </div>
    </Container>
  );
}

export default DragonFlowsVisual;
