/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/system";
import useSendMessage from "./useSendMessage";
import JsonRenderer from "./JsonRenderer";

const names = [
  "Alex",
  "Avery",
  "Arden",
  "Ash",
  "Aspen",
  "Bailey",
  "Blake",
  "Brett",
  "Brook",
  "Bryn",
  "Cameron",
  "Casey",
  "Charlie",
  "Corey",
  "Cruz",
  "Dakota",
  "Dallas",
  "Darian",
  "Drew",
  "Dylan",
  "Eden",
  "Ellis",
  "Emerson",
  "Emery",
  "Ezra",
  "Finley",
  "Frankie",
  "Flynn",
  "Frost",
  "Fable",
  "Gray",
  "Gale",
  "Genesis",
  "Glenn",
  "Greer",
  "Harper",
  "Hayden",
  "Hunter",
  "Hollis",
  "Haven",
  "Indigo",
  "Ira",
  "Ivory",
  "Israel",
  "Ivy",
  "Jordan",
  "Jamie",
  "Jules",
  "Justice",
  "Jay",
  "Kai",
  "Kendall",
  "Karter",
  "Keegan",
  "Kyrie",
  "Lane",
  "Logan",
  "Lennon",
  "Luca",
  "Lyric",
  "Morgan",
  "Micah",
  "Marlow",
  "Monroe",
  "Milan",
  "Nico",
  "Noel",
  "Nova",
  "Navy",
  "Nile",
  "Oakley",
  "Ollie",
  "Onyx",
  "Ocean",
  "Orion",
  "Parker",
  "Phoenix",
  "Peyton",
  "Paris",
  "Perry",
  "Quinn",
  "Quest",
  "Quincy",
  "Quinlan",
  "Quill",
  "River",
  "Riley",
  "Reese",
  "Rowan",
  "Rory",
  "Skyler",
  "Sage",
  "Sawyer",
  "Shiloh",
  "Spencer",
  "Taylor",
  "Teagan",
  "Toby",
  "Toni",
  "Tatum",
  "Urban",
  "Umber",
  "Unique",
  "Uriah",
  "Upton",
  "Val",
  "Vesper",
  "Vaughn",
  "Vega",
  "Vale",
  "West",
  "Wren",
  "Winter",
  "Wynn",
  "Wiley",
  "Xen",
  "Xenon",
  "Xander",
  "Xavi",
  "Xian",
  "Yale",
  "Yarrow",
  "Yuri",
  "Yael",
  "Yule",
  "Zion",
  "Zane",
  "Zephyr",
  "Zuri",
  "Zion",
];
const genders = ["Male", "Female", "Gender Neutral"];

const raceSpecies = [
  "Human",
  "Elf",
  "Dwarf",
  "Orc",
  "Halfling",
  "Gnome",
  "Goblin",
  "Troll",
  "Ogre",
  "Lizardfolk",
  "Fairy",
  "Giant",
  "Merfolk",
  "Centaur",
  "Minotaur",
  "Satyr",
  "Undead",
  "Elemental",
  "Spirit",
  "Beastfolk",
];

const classProfession = [
  "Warrior",
  "Mage",
  "Rogue",
  "Cleric",
  "Ranger",
  "Druid",
  "Bard",
  "Barbarian",
  "Assassin",
  "Alchemist",
  "Knight",
  "Shaman",
  "Thief",
  "Hunter",
  "Priest",
  "Sorcerer",
  "Monk",
  "Necromancer",
  "Scout",
  "Mercenary",
];

const facialFeatures = {
  Eyes: [
    "glowing",
    "cat-like",
    "one-eyed",
    "blind",
    "mismatched",
    "scarred",
    "piercing",
    "gentle",
    "fiery",
    "cold",
    "sparkling",
    "haunted",
    "hollow",
    "vibrant",
    "mystical",
    "golden",
    "emerald",
    "sapphire",
    "ruby",
    "obsidian",
  ],
  Ears: [
    "pointed",
    "long",
    "pierced",
    "tattooed",
    "furry",
    "scaled",
    "tattered",
    "elf-like",
    "rounded",
    "lobed",
  ],
  Nose: [
    "button",
    "hawk-like",
    "broken",
    "snub",
    "aquiline",
    "pig-like",
    "hooked",
    "beak-like",
    "flat",
    "crooked",
  ],
  Mouth: [
    "fanged",
    "toothless",
    "smirking",
    "grim",
    "smiling",
    "scowling",
    "moustached",
    "bearded",
    "lipless",
    "whiskered",
  ],
  Skin: [
    "scaled",
    "furred",
    "feathered",
    "tattooed",
    "scarred",
    "glowing",
    "transparent",
    "stone-like",
    "wooden",
    "metallic",
    "mottled",
    "pale",
    "dark",
    "freckled",
    "branded",
  ],
};

const hair = {
  HairType: [
    "flowing",
    "braided",
    "dreadlocked",
    "shaved",
    "mohawk",
    "topknot",
    "wild",
    "spiky",
    "curly",
    "straight",
    "wavy",
    "bald",
    "ponytail",
    "bun",
    "long",
    "short",
    "shoulder-length",
    "glowing",
    "fiery",
    "leafy",
  ],
};

const bodyFeatures = {
  Build: [
    "muscular",
    "slender",
    "plump",
    "gaunt",
    "hunched",
    "towering",
    "dwarfish",
    "giant",
    "lithe",
    "sturdy",
    "nimble",
    "bulky",
    "agile",
    "frail",
    "bony",
    "stocky",
    "robust",
    "shadowy",
    "ethereal",
    "ghostly",
  ],
  DistinctiveFeatures: [
    "wings",
    "tail",
    "horns",
    "tentacles",
    "third eye",
    "extra arms",
    "claws",
    "hooves",
    "talons",
    "webbed feet",
    "gills",
    "fins",
    "shell",
    "fur",
    "scales",
    "feathers",
    "glowing aura",
    "floating",
    "translucent",
    "mechanical limb",
  ],
  SkinTone: [
    "pale",
    "tanned",
    "dark",
    "ashen",
    "golden",
    "silver",
    "bronze",
    "copper",
    "emerald",
    "sapphire",
    "ruby",
    "onyx",
    "ivory",
    "pearl",
    "obsidian",
    "translucent",
    "glowing",
    "shadowy",
    "fiery",
    "mottled",
  ],
};

const clothingArmor = {
  ClothingStyle: [
    "robes",
    "leather armor",
    "chainmail",
    "plate armor",
    "rags",
    "elegant attire",
    "traveling cloak",
    "hooded cloak",
    "tunic",
    "gown",
    "military uniform",
    "ritual garments",
    "tribal wear",
    "noble's outfit",
    "commoner's clothes",
    "stealthy attire",
    "desert robes",
    "arctic gear",
    "monk's robes",
    "priestly vestments",
  ],
  Accessories: [
    "amulet",
    "ring",
    "bracelet",
    "earrings",
    "belt",
    "gloves",
    "boots",
    "hat",
    "helmet",
    "mask",
    "scarf",
    "cloak",
    "quiver",
    "backpack",
    "sash",
    "goggles",
    "bracers",
    "pauldrons",
    "greaves",
    "cape",
  ],
};

const equipment = {
  Weapons: [
    "sword",
    "dagger",
    "axe",
    "bow",
    "crossbow",
    "staff",
    "spear",
    "mace",
    "hammer",
    "flail",
    "scythe",
    "whip",
    "trident",
    "club",
    "slingshot",
    "chakram",
    "rapier",
    "morning star",
    "polearm",
    "sling",
  ],
  Tools: [
    "wand",
    "staff",
    "spellbook",
    "herbal kit",
    "alchemy kit",
    "musical instrument",
    "lockpick set",
    "map",
    "compass",
    "lantern",
    "rope",
    "grappling hook",
    "torch",
    "fishing rod",
    "hammer and chisel",
    "shovel",
    "cooking utensils",
    "water skin",
    "first aid kit",
    "quill and ink",
  ],
};

const magicalAbilities = {
  Abilities: [
    "fire magic",
    "ice magic",
    "lightning magic",
    "healing",
    "illusion",
    "shape-shifting",
    "teleportation",
    "telekinesis",
    "mind reading",
    "elemental control",
    "summoning",
    "curse casting",
    "blessing",
    "time manipulation",
    "invisibility",
    "flight",
    "shadow manipulation",
    "light manipulation",
    "nature magic",
    "astral projection",
  ],
};

const personalityTraits = [
  "brave",
  "cowardly",
  "wise",
  "foolish",
  "kind",
  "cruel",
  "loyal",
  "treacherous",
  "honest",
  "deceitful",
  "cheerful",
  "gloomy",
  "greedy",
  "generous",
  "curious",
  "apathetic",
  "arrogant",
  "humble",
  "just",
  "corrupt",
  "courageous",
  "timid",
  "vengeful",
  "forgiving",
  "ambitious",
  "lazy",
  "patient",
  "impulsive",
  "optimistic",
  "pessimistic",
  "stoic",
  "emotional",
  "pragmatic",
  "idealistic",
  "sarcastic",
  "sincere",
  "noble",
  "selfish",
  "charismatic",
  "awkward",
];

const background = {
  Origin: [
    "noble",
    "commoner",
    "orphan",
    "outcast",
    "hermit",
    "soldier",
    "merchant",
    "scholar",
    "thief",
    "pirate",
    "farmer",
    "blacksmith",
    "alchemist",
    "monk",
    "assassin",
    "bard",
    "hunter",
    "sailor",
    "priest",
    "slave",
  ],
  Motivation: [
    "revenge",
    "justice",
    "wealth",
    "knowledge",
    "power",
    "redemption",
    "duty",
    "love",
    "survival",
    "freedom",
    "fame",
    "adventure",
    "faith",
    "honor",
    "protect loved ones",
    "destroy evil",
    "find a cure",
    "restore balance",
    "fulfill a prophecy",
    "escape past",
  ],
};

const voice = {
  Tone: [
    "gruff",
    "melodic",
    "raspy",
    "whispery",
    "booming",
    "hissing",
    "growling",
    "ethereal",
    "mournful",
    "jovial",
    "menacing",
    "sultry",
    "monotone",
    "breathy",
    "thunderous",
    "silky",
    "nasal",
    "baritone",
    "soprano",
    "tenor",
  ],
  Accent: [
    "northern",
    "southern",
    "eastern",
    "western",
    "mystic",
    "ancient",
    "foreign",
    "gruff",
    "refined",
    "rural",
    "urban",
    "nasal",
    "guttural",
    "whispered",
    "musical",
    "harsh",
    "soft",
    "lilting",
    "staccato",
    "drawling",
  ],
  SpeechPatterns: [
    "riddles",
    "proverbs",
    "stuttering",
    "poetic",
    "laconic",
    "verbose",
    "singsong",
    "grumbling",
    "chanting",
    "slurred",
    "haughty",
    "formal",
    "colloquial",
    "broken speech",
    "eerie whispers",
    "laughs between words",
    "pauses frequently",
    "over-enunciates",
    "mimics others",
    "speaks in third person",
  ],
};

const miscellaneous = {
  Quirks: [
    "always humming",
    "taps foot",
    "laughs nervously",
    "collects odd items",
    "superstitious",
    "talks to self",
    "writes everything down",
    "afraid of the dark",
    "obsessed with fire",
    "vegetarian",
    "refuses to use weapons",
    "compulsive gambler",
    "allergic to magic",
    "never lies",
    "speaks only in rhymes",
    "can't swim",
    "night owl",
    "early riser",
    "hates the color red",
    "has a pet familiar",
  ],
  FearsPhobias: [
    "heights",
    "spiders",
    "crowds",
    "open spaces",
    "closed spaces",
    "fire",
    "water",
    "magic",
    "death",
    "undead",
    "the dark",
    "monsters",
    "being alone",
    "losing control",
    "blood",
    "storms",
    "ghosts",
    "failure",
    "betrayal",
    "unknown",
  ],
};

const colors = [
  { name: "Indian Red", hex: "#CD5C5C" },
  { name: "Light Coral", hex: "#F08080" },
  { name: "Salmon", hex: "#FA8072" },
  { name: "Dark Salmon", hex: "#E9967A" },
  { name: "Light Salmon", hex: "#FFA07A" },
  { name: "Crimson", hex: "#DC143C" },
  { name: "Red", hex: "#FF0000" },
  { name: "Brick Red", hex: "#B22222" },
  { name: "Dark Red", hex: "#8B0000" },

  { name: "Pink", hex: "#FFC0CB" },
  { name: "Light Pink", hex: "#FFB6C1" },
  { name: "Hot Pink", hex: "#FF69B4" },
  { name: "Deep Pink", hex: "#FF1493" },
  { name: "Medium Violet Red", hex: "#C71585" },
  { name: "Pale Violet Red", hex: "#DB7093" },

  { name: "Light Salmon", hex: "#FFA07A" },
  { name: "Coral", hex: "#FF7F50" },
  { name: "Tomato", hex: "#FF6347" },
  { name: "Orange Red", hex: "#FF4500" },
  { name: "Dark Orange", hex: "#FF8C00" },
  { name: "Orange", hex: "#FFA500" },

  { name: "Gold", hex: "#FFD700" },
  { name: "Yellow", hex: "#FFFF00" },
  { name: "Light Yellow", hex: "#FFFFE0" },
  { name: "Lemon Chiffon", hex: "#FFFACD" },
  { name: "Light Goldenrod Yellow", hex: "#FAFAD2" },
  { name: "Papaya Whip", hex: "#FFEFD5" },
  { name: "Moccasin", hex: "#FFE4B5" },
  { name: "Peach Puff", hex: "#FFDAB9" },
  { name: "Pale Goldenrod", hex: "#EEE8AA" },
  { name: "Khaki", hex: "#F0E68C" },
  { name: "Dark Khaki", hex: "#BDB76B" },

  { name: "Lavender", hex: "#E6E6FA" },
  { name: "Thistle", hex: "#D8BFD8" },
  { name: "Plum", hex: "#DDA0DD" },
  { name: "Violet", hex: "#EE82EE" },
  { name: "Orchid", hex: "#DA70D6" },
  { name: "Fuchsia", hex: "#FF00FF" },
  { name: "Magenta", hex: "#FF00FF" },
  { name: "Medium Orchid", hex: "#BA55D3" },
  { name: "Medium Purple", hex: "#9370DB" },
  { name: "Rebecca Purple", hex: "#663399" },
  { name: "Blue Violet", hex: "#8A2BE2" },
  { name: "Dark Violet", hex: "#9400D3" },
  { name: "Dark Orchid", hex: "#9932CC" },
  { name: "Dark Magenta", hex: "#8B008B" },
  { name: "Purple", hex: "#800080" },
  { name: "Indigo", hex: "#4B0082" },
  { name: "Slate Blue", hex: "#6A5ACD" },
  { name: "Dark Slate Blue", hex: "#483D8B" },
  { name: "Medium Slate Blue", hex: "#7B68EE" },

  { name: "Green Yellow", hex: "#ADFF2F" },
  { name: "Chartreuse", hex: "#7FFF00" },
  { name: "Lawn Green", hex: "#7CFC00" },
  { name: "Lime", hex: "#00FF00" },
  { name: "Lime Green", hex: "#32CD32" },
  { name: "Pale Green", hex: "#98FB98" },
  { name: "Light Green", hex: "#90EE90" },
  { name: "Medium Spring Green", hex: "#00FA9A" },
  { name: "Spring Green", hex: "#00FF7F" },
  { name: "Medium Sea Green", hex: "#3CB371" },
  { name: "Sea Green", hex: "#2E8B57" },
  { name: "Forest Green", hex: "#228B22" },
  { name: "Green", hex: "#008000" },
  { name: "Dark Green", hex: "#006400" },
  { name: "Yellow Green", hex: "#9ACD32" },
  { name: "Olive Drab", hex: "#6B8E23" },
  { name: "Olive", hex: "#808000" },
  { name: "Dark Olive Green", hex: "#556B2F" },
  { name: "Medium Aquamarine", hex: "#66CDAA" },
  { name: "Dark Sea Green", hex: "#8FBC8B" },
  { name: "Light Sea Green", hex: "#20B2AA" },
  { name: "Dark Cyan", hex: "#008B8B" },
  { name: "Teal", hex: "#008080" },

  { name: "Aqua", hex: "#00FFFF" },
  { name: "Cyan", hex: "#00FFFF" },
  { name: "Light Cyan", hex: "#E0FFFF" },
  { name: "Pale Turquoise", hex: "#AFEEEE" },
  { name: "Aquamarine", hex: "#7FFFD4" },
  { name: "Turquoise", hex: "#40E0D0" },
  { name: "Medium Turquoise", hex: "#48D1CC" },
  { name: "Dark Turquoise", hex: "#00CED1" },
  { name: "Cadet Blue", hex: "#5F9EA0" },
  { name: "Steel Blue", hex: "#4682B4" },
  { name: "Light Steel Blue", hex: "#B0C4DE" },
  { name: "Powder Blue", hex: "#B0E0E6" },
  { name: "Light Blue", hex: "#ADD8E6" },
  { name: "Sky Blue", hex: "#87CEEB" },
  { name: "Light Sky Blue", hex: "#87CEFA" },
  { name: "Deep Sky Blue", hex: "#00BFFF" },
  { name: "Dodger Blue", hex: "#1E90FF" },
  { name: "Cornflower Blue", hex: "#6495ED" },
  { name: "Medium Slate Blue", hex: "#7B68EE" },
  { name: "Royal Blue", hex: "#4169E1" },
  { name: "Blue", hex: "#0000FF" },
  { name: "Medium Blue", hex: "#0000CD" },
  { name: "Dark Blue", hex: "#00008B" },
  { name: "Navy", hex: "#000080" },
  { name: "Midnight Blue", hex: "#191970" },

  { name: "Cornsilk", hex: "#FFF8DC" },
  { name: "Blanched Almond", hex: "#FFEBCD" },
  { name: "Bisque", hex: "#FFE4C4" },
  { name: "Navajo White", hex: "#FFDEAD" },
  { name: "Wheat", hex: "#F5DEB3" },
  { name: "Burly Wood", hex: "#DEB887" },
  { name: "Tan", hex: "#D2B48C" },
  { name: "Rosy Brown", hex: "#BC8F8F" },
  { name: "Sandy Brown", hex: "#F4A460" },
  { name: "Goldenrod", hex: "#DAA520" },
  { name: "Dark Goldenrod", hex: "#B8860B" },
  { name: "Peru", hex: "#CD853F" },
  { name: "Chocolate", hex: "#D2691E" },
  { name: "Saddle Brown", hex: "#8B4513" },
  { name: "Sienna", hex: "#A0522D" },
  { name: "Brown", hex: "#A52A2A" },
  { name: "Maroon", hex: "#800000" },

  { name: "White", hex: "#FFFFFF" },
  { name: "Snow", hex: "#FFFAFA" },
  { name: "Honey Dew", hex: "#F0FFF0" },
  { name: "Mint Cream", hex: "#F5FFFA" },
  { name: "Azure", hex: "#F0FFFF" },
  { name: "Alice Blue", hex: "#F0F8FF" },
  { name: "Ghost White", hex: "#F8F8FF" },
  { name: "White Smoke", hex: "#F5F5F5" },
  { name: "Sea Shell", hex: "#FFF5EE" },
  { name: "Beige", hex: "#F5F5DC" },
  { name: "Old Lace", hex: "#FDF5E6" },
  { name: "Floral White", hex: "#FFFAF0" },
  { name: "Ivory", hex: "#FFFFF0" },
  { name: "Antique White", hex: "#FAEBD7" },
  { name: "Linen", hex: "#FAF0E6" },
  { name: "Lavender Blush", hex: "#FFF0F5" },
  { name: "MistyRose", hex: "#FFE4E1" },

  { name: "Gainsboro", hex: "#DCDCDC" },
  { name: "Light Gray", hex: "#D3D3D3" },
  { name: "Silver", hex: "#C0C0C0" },
  { name: "Dark Gray", hex: "#A9A9A9" },
  { name: "Gray", hex: "#808080" },
  { name: "Dim Gray", hex: "#696969" },
  { name: "Light Slate Gray", hex: "#778899" },
  { name: "Slate Gray", hex: "#708090" },
  { name: "Dark Slate Gray", hex: "#2F4F4F" },
  { name: "Black", hex: "#000000" },
];

const ColorBox = styled(Box)(({ color }) => ({
  width: 40,
  height: 40,
  backgroundColor: color,
  cursor: "pointer",
  border: "1px solid #000",
}));

const CharacterCreation = () => {
  const [availableNames, setAvailableNames] = useState(names.slice(0, 5));
  const [availableGenders, setAvailableGenders] = useState(genders);
  const [availableRaces, setAvailableRaces] = useState(raceSpecies);
  const [availableCharacterClasses, setAvailableCharacterClasses] =
    useState(classProfession);

  const [availableFeatures, setAvailableFeatures] = useState(() => {
    const newAvailableFeatures = {};
    Object.keys(facialFeatures).forEach((category) => {
      newAvailableFeatures[category] = facialFeatures[category].slice(0, 5);
    });
    return newAvailableFeatures;
  });

  const [availableHair, setAvailableHair] = useState(() => {
    const newAvailableHair = {};
    Object.keys(hair).forEach((category) => {
      newAvailableHair[category] = hair[category].slice(0, 5);
    });
    return newAvailableHair;
  });

  const [availableBody, setAvailableBody] = useState(() => {
    const newAvailableBody = {};
    Object.keys(bodyFeatures).forEach((category) => {
      newAvailableBody[category] = bodyFeatures[category].slice(0, 5);
    });
    return newAvailableBody;
  });

  const [availablePersonalityTraits, setAvailablePersonalityTraits] = useState(
  personalityTraits.slice(0, 5)
);

const [availableClothingArmor, setAvailableClothingArmor] = useState(() => {
  const newAvailableClothingArmor = {};
  Object.keys(clothingArmor).forEach((category) => {
    newAvailableClothingArmor[category] = clothingArmor[category].slice(0, 5);
  });
  return newAvailableClothingArmor;
});

const [availableEquipment, setAvailableEquipment] = useState(() => {
  const newAvailableEquipment = {};
  Object.keys(equipment).forEach((category) => {
    newAvailableEquipment[category] = equipment[category].slice(0, 5);
  });
  return newAvailableEquipment;
});

const [availableMagicalAbilities, setAvailableMagicalAbilities] = useState(() => {
  const newAvailableMagicalAbilities = {};
  Object.keys(magicalAbilities).forEach((category) => {
    newAvailableMagicalAbilities[category] = magicalAbilities[category].slice(0, 5);
  });
  return newAvailableMagicalAbilities;
});

const [availableBackground, setAvailableBackground] = useState(() => {
  const newAvailableBackground = {};
  Object.keys(background).forEach((category) => {
    newAvailableBackground[category] = background[category].slice(0, 5);
  });
  return newAvailableBackground;
});

const [availableVoice, setAvailableVoice] = useState(() => {
  const newAvailableVoice = {};
  Object.keys(voice).forEach((category) => {
    newAvailableVoice[category] = voice[category].slice(0, 5);
  });
  return newAvailableVoice;
});
  
  const [availableMiscellaneous, setAvailableMiscellaneous] = useState(() => {
    const newAvailableMiscellaneous = {};
    Object.keys(miscellaneous).forEach((category) => {
      newAvailableMiscellaneous[category] = miscellaneous[category].slice(0, 5);
    });
    return newAvailableMiscellaneous;
  });

  const [selectedName, setSelectedName] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedRace, setSelectedRace] = useState("");
  const [selectedCharacterClass, setSelectedCharacterClass] = useState("");
  const [selectedFeatures, setSelectedFeatures] = useState({});
  const [selectedHair, setSelectedHair] = useState({});
  const [selectedBody, setSelectedBody] = useState({});
  const [selectedClothingArmor, setSelectedClothingArmor] = useState({});
  const [selectedEquipment, setSelectedEquipment] = useState({});
  const [selectedMagicalAbilities, setSelectedMagicalAbilities] = useState({});
  const [selectedPersonalityTraits, setSelectedPersonalityTraits] = useState([]);
  const [selectedBackground, setSelectedBackground] = useState({});
  const [selectedVoice, setSelectedVoice] = useState({});
  const [selectedMiscellaneous, setSelectedMiscellaneous] = useState({});
  const [eyeColorDialogOpen, setEyeColorDialogOpen] = useState(false);
  const [hairColorDialogOpen, setHairColorDialogOpen] = useState(false);
  const [randomEyeColors, setRandomEyeColors] = useState(
    colors.sort(() => 0.5 - Math.random()).slice(0, 25)
  );
  const [randomHairColors, setRandomHairColors] = useState(
    colors.sort(() => 0.5 - Math.random()).slice(0, 25)
  );

  const { handleSendMessage, loading, error, result } = useSendMessage();

  const randomizeOptions = () => {
    const shuffledNames = names.sort(() => 0.5 - Math.random());
    let newAvailableNames = [...shuffledNames.slice(0, 5)];
    if (selectedName) {
      newAvailableNames[0] = selectedName;
    }
    setAvailableNames(newAvailableNames);

    const newAvailableFeatures = {};
    Object.keys(facialFeatures).forEach((category) => {
      const currentSelections = selectedFeatures[category] || [];
      const remainingFeatures = facialFeatures[category].filter(
        (feature) => !currentSelections.includes(feature)
      );
      const shuffledFeatures = remainingFeatures.sort(
        () => 0.5 - Math.random()
      );
      newAvailableFeatures[category] = [
        ...currentSelections,
        ...shuffledFeatures.slice(0, 5 - currentSelections.length),
      ];
    });
    setAvailableFeatures(newAvailableFeatures);

    const newAvailableHair = {};
    Object.keys(hair).forEach((category) => {
      const currentSelections = selectedHair[category] || [];
      const remainingFeatures = hair[category].filter(
        (feature) => !currentSelections.includes(feature)
      );
      const shuffledFeatures = remainingFeatures.sort(
        () => 0.5 - Math.random()
      );
      newAvailableHair[category] = [
        ...currentSelections,
        ...shuffledFeatures.slice(0, 5 - currentSelections.length),
      ];
    });
    setAvailableHair(newAvailableHair);

    const newAvailableBody = {};
    Object.keys(bodyFeatures).forEach((category) => {
      const currentSelections = selectedBody[category] || [];
      const remainingFeatures = bodyFeatures[category].filter(
        (feature) => !currentSelections.includes(feature)
      );
      const shuffledFeatures = remainingFeatures.sort(
        () => 0.5 - Math.random()
      );
      newAvailableBody[category] = [
        ...currentSelections,
        ...shuffledFeatures.slice(0, 5 - currentSelections.length),
      ];
    });
    setAvailableBody(newAvailableBody);

  // Randomize Personality Traits (flat array)
  const shuffledPersonalityTraits = personalityTraits.sort(() => 0.5 - Math.random());
  const currentPersonalitySelections = selectedPersonalityTraits || [];
  const remainingPersonalityTraits = shuffledPersonalityTraits.filter(
    (trait) => !currentPersonalitySelections.includes(trait)
  );
  const newAvailablePersonalityTraits = [
    ...currentPersonalitySelections,
    ...remainingPersonalityTraits.slice(0, 5 - currentPersonalitySelections.length),
  ];
  setAvailablePersonalityTraits(newAvailablePersonalityTraits);

  // Randomize Clothing & Armor (object with categories)
  const newAvailableClothingArmor = {};
  Object.keys(clothingArmor).forEach((category) => {
    const currentSelections = selectedClothingArmor[category] || [];
    const remainingItems = clothingArmor[category].filter(
      (item) => !currentSelections.includes(item)
    );
    const shuffledItems = remainingItems.sort(() => 0.5 - Math.random());
    newAvailableClothingArmor[category] = [
      ...currentSelections,
      ...shuffledItems.slice(0, 5 - currentSelections.length),
    ];
  });
  setAvailableClothingArmor(newAvailableClothingArmor);

  // Randomize Equipment (object with categories)
  const newAvailableEquipment = {};
  Object.keys(equipment).forEach((category) => {
    const currentSelections = selectedEquipment[category] || [];
    const remainingItems = equipment[category].filter(
      (item) => !currentSelections.includes(item)
    );
    const shuffledItems = remainingItems.sort(() => 0.5 - Math.random());
    newAvailableEquipment[category] = [
      ...currentSelections,
      ...shuffledItems.slice(0, 5 - currentSelections.length),
    ];
  });
  setAvailableEquipment(newAvailableEquipment);

  // Randomize Magical Abilities (object with categories)
  const newAvailableMagicalAbilities = {};
  Object.keys(magicalAbilities).forEach((category) => {
    const currentSelections = selectedMagicalAbilities[category] || [];
    const remainingItems = magicalAbilities[category].filter(
      (item) => !currentSelections.includes(item)
    );
    const shuffledItems = remainingItems.sort(() => 0.5 - Math.random());
    newAvailableMagicalAbilities[category] = [
      ...currentSelections,
      ...shuffledItems.slice(0, 5 - currentSelections.length),
    ];
  });
  setAvailableMagicalAbilities(newAvailableMagicalAbilities);

  // Randomize Background (object with categories)
  const newAvailableBackground = {};
  Object.keys(background).forEach((category) => {
    const currentSelections = selectedBackground[category] || [];
    const remainingItems = background[category].filter(
      (item) => !currentSelections.includes(item)
    );
    const shuffledItems = remainingItems.sort(() => 0.5 - Math.random());
    newAvailableBackground[category] = [
      ...currentSelections,
      ...shuffledItems.slice(0, 5 - currentSelections.length),
    ];
  });
  setAvailableBackground(newAvailableBackground);

  // Randomize Voice (object with categories)
  const newAvailableVoice = {};
  Object.keys(voice).forEach((category) => {
    const currentSelections = selectedVoice[category] || [];
    const remainingItems = voice[category].filter(
      (item) => !currentSelections.includes(item)
    );
    const shuffledItems = remainingItems.sort(() => 0.5 - Math.random());
    newAvailableVoice[category] = [
      ...currentSelections,
      ...shuffledItems.slice(0, 5 - currentSelections.length),
    ];
  });
  setAvailableVoice(newAvailableVoice);
    
    const newAvailableMiscellaneous = {};
    Object.keys(miscellaneous).forEach((category) => {
      const currentSelections = selectedMiscellaneous[category] || [];
      const remainingFeatures = miscellaneous[category].filter(
        (feature) => !currentSelections.includes(feature)
      );
      const shuffledFeatures = remainingFeatures.sort(
        () => 0.5 - Math.random()
      );
      newAvailableMiscellaneous[category] = [
        ...currentSelections,
        ...shuffledFeatures.slice(0, 5 - currentSelections.length),
      ];
    });
    setAvailableMiscellaneous(newAvailableMiscellaneous);
  };

  const handleNameSelect = (name) => {
    setSelectedName(name);
  };

  const handleGenderSelect = (gender) => {
    setSelectedGender(gender);
  };

  const handleRaceSelect = (race) => {
    setSelectedRace(race);
  };

  const handleCharacterClassSelect = (characterClass) => {
    setSelectedCharacterClass(characterClass);
  };

  const handleFeatureSelect = (category, feature) => {
    setSelectedFeatures((prevFeatures) => {
      const newFeatures = { ...prevFeatures };
      if (newFeatures[category]?.includes(feature)) {
        newFeatures[category] = newFeatures[category].filter(
          (item) => item !== feature
        );
      } else {
        newFeatures[category] = newFeatures[category]
          ? [...newFeatures[category], feature]
          : [feature];
      }
      return newFeatures;
    });
  };

  const handleHairSelect = (category, feature) => {
    setSelectedHair((prevHair) => {
      const newHair = { ...prevHair };
      if (newHair[category]?.includes(feature)) {
        newHair[category] = newHair[category].filter(
          (item) => item !== feature
        );
      } else {
        newHair[category] = newHair[category]
          ? [...newHair[category], feature]
          : [feature];
      }
      return newHair;
    });
  };

  const handleBodySelect = (category, feature) => {
    setSelectedBody((prevBody) => {
      const newBody = { ...prevBody };
      if (newBody[category]?.includes(feature)) {
        newBody[category] = newBody[category].filter(
          (item) => item !== feature
        );
      } else {
        newBody[category] = newBody[category]
          ? [...newBody[category], feature]
          : [feature];
      }
      return newBody;
    });
  };

  const handleClothingArmorSelect = (category, feature) => {
  setSelectedClothingArmor((prevClothingArmor) => {
    const newClothingArmor = { ...prevClothingArmor };
    if (newClothingArmor[category]?.includes(feature)) {
      newClothingArmor[category] = newClothingArmor[category].filter(
        (item) => item !== feature
      );
    } else {
      newClothingArmor[category] = newClothingArmor[category]
        ? [...newClothingArmor[category], feature]
        : [feature];
    }
    return newClothingArmor;
  });
};

const handleEquipmentSelect = (category, feature) => {
  setSelectedEquipment((prevEquipment) => {
    const newEquipment = { ...prevEquipment };
    if (newEquipment[category]?.includes(feature)) {
      newEquipment[category] = newEquipment[category].filter(
        (item) => item !== feature
      );
    } else {
      newEquipment[category] = newEquipment[category]
        ? [...newEquipment[category], feature]
        : [feature];
    }
    return newEquipment;
  });
};

const handleMagicalAbilitiesSelect = (category, ability) => {
  setSelectedMagicalAbilities((prevAbilities) => {
    const newAbilities = { ...prevAbilities };
    if (newAbilities[category]?.includes(ability)) {
      newAbilities[category] = newAbilities[category].filter(
        (item) => item !== ability
      );
    } else {
      newAbilities[category] = newAbilities[category]
        ? [...newAbilities[category], ability]
        : [ability];
    }
    return newAbilities;
  });
};

const handlePersonalityTraitSelect = (trait) => {
  setSelectedPersonalityTraits((prevTraits) => {
    if (prevTraits.includes(trait)) {
      return prevTraits.filter((item) => item !== trait);
    } else {
      return [...prevTraits, trait];
    }
  });
};

const handleBackgroundSelect = (category, feature) => {
  setSelectedBackground((prevBackground) => {
    const newBackground = { ...prevBackground };
    if (newBackground[category]?.includes(feature)) {
      newBackground[category] = newBackground[category].filter(
        (item) => item !== feature
      );
    } else {
      newBackground[category] = newBackground[category]
        ? [...newBackground[category], feature]
        : [feature];
    }
    return newBackground;
  });
};
  
  const handleVoiceSelect = (category, feature) => {
    setSelectedVoice((prevVoice) => {
      const newVoice = { ...prevVoice };
      if (newVoice[category]?.includes(feature)) {
        newVoice[category] = newVoice[category].filter(
          (item) => item !== feature
        );
      } else {
        newVoice[category] = newVoice[category]
          ? [...newVoice[category], feature]
          : [feature];
      }
      return newVoice;
    });
  };

  const handleMiscellaneousSelect = (category, feature) => {
    setSelectedMiscellaneous((prevMiscellaneous) => {
      const newMiscellaneous = { ...prevMiscellaneous };
      if (newMiscellaneous[category]?.includes(feature)) {
        newMiscellaneous[category] = newMiscellaneous[category].filter(
          (item) => item !== feature
        );
      } else {
        newMiscellaneous[category] = newMiscellaneous[category]
          ? [...newMiscellaneous[category], feature]
          : [feature];
      }
      return newMiscellaneous;
    });
  };

  const clearSelections = () => {
    setSelectedName("");
    setSelectedGender("");
    setSelectedRace("");
    setSelectedCharacterClass("");
    setSelectedFeatures({});
    setSelectedHair({});
    setSelectedBody({});
    setSelectedClothingArmor({});
    setSelectedEquipment({});
    setSelectedMagicalAbilities({});
    setSelectedPersonalityTraits([]);
    setSelectedBackground({});
    setSelectedVoice({});
    setSelectedMiscellaneous({});
  };

  const handleSubmit = async () => {
    const character = {
      name: selectedName,
      gender: selectedGender,
      features: selectedFeatures,
    };
    const facialFeaturesString = `Facial Features: ${Object.entries(
      character.features
    )
      .filter(([key, value]) => value.length > 0)
      .map(
        ([key, value]) =>
          `${value
            .map((item) => item.replace(/_/g, " "))
            .join(", ")} ${key.replace(/_/g, " ")}`
      )
      .join(", ")}`;

    const hairString = `Hair: ${Object.entries(selectedHair)
      .filter(([key, value]) => value.length > 0)
      .map(
        ([key, value]) =>
          `${value
            .map((item) => item.replace(/_/g, " "))
            .join(", ")} ${key.replace(/_/g, " ")}`
      )
      .join(", ")}`;

    const bodyString = `Body: ${Object.entries(selectedBody)
      .filter(([key, value]) => value.length > 0)
      .map(
        ([key, value]) =>
          `${value
            .map((item) => item.replace(/_/g, " "))
            .join(", ")} ${key.replace(/_/g, " ")}`
      )
      .join(", ")}`;
      
  const clothingArmorString = `Clothing/Armor: ${Object.entries(
    selectedClothingArmor
  )
    .filter(([key, value]) => value.length > 0)
    .map(
      ([key, value]) =>
        `${value
          .map((item) => item.replace(/_/g, " "))
          .join(", ")} ${key.replace(/_/g, " ")}`
    )
    .join(", ")}`;
    
  const equipmentString = `Equipment: ${Object.entries(selectedEquipment)
    .filter(([key, value]) => value.length > 0)
    .map(
      ([key, value]) =>
        `${value
          .map((item) => item.replace(/_/g, " "))
          .join(", ")} ${key.replace(/_/g, " ")}`
    )
    .join(", ")}`;

  const magicalAbilitiesString = `Magical Abilities: ${Object.entries(
    selectedMagicalAbilities
  )
    .filter(([key, value]) => value.length > 0)
    .map(
      ([key, value]) =>
        `${value
          .map((item) => item.replace(/_/g, " "))
          .join(", ")} ${key.replace(/_/g, " ")}`
    )
    .join(", ")}`;

  const personalityTraitsString = `Personality Traits: ${selectedPersonalityTraits
    .map((trait) => trait.replace(/_/g, " "))
    .join(", ")}`;

  const backgroundString = `Background: ${Object.entries(selectedBackground)
    .filter(([key, value]) => value.length > 0)
    .map(
      ([key, value]) =>
        `${value
          .map((item) => item.replace(/_/g, " "))
          .join(", ")} ${key.replace(/_/g, " ")}`
    )
    .join(", ")}`;

    const voiceString = `Voice: ${Object.entries(selectedVoice)
      .filter(([key, value]) => value.length > 0)
      .map(
        ([key, value]) =>
          `${value
            .map((item) => item.replace(/_/g, " "))
            .join(", ")} ${key.replace(/_/g, " ")}`
      )
      .join(", ")}`;

    const miscellaneousString = `Miscellaneous: ${Object.entries(
      selectedMiscellaneous
    )
      .filter(([key, value]) => value.length > 0)
      .map(
        ([key, value]) =>
          `${value
            .map((item) => item.replace(/_/g, " "))
            .join(", ")} ${key.replace(/_/g, " ")}`
      )
      .join(", ")}`;

    const prefix = "character portrait, Fantasy RPG, digital watercolor";
    const suffix =
      "digital art, movie still, marketing materials, dramatic lighting, high detail, realistic, facing camera, simple background";

    const characterString = `Name: ${character.name}, Gender: ${character.gender}, Race: ${selectedRace}, Class: ${selectedCharacterClass}, ${facialFeaturesString}, ${hairString}, ${bodyString}, ${clothingArmorString}, ${equipmentString}, ${magicalAbilitiesString}, ${personalityTraitsString}, ${backgroundString}, ${voiceString}, ${miscellaneousString}`;
    const prompt = `${prefix}, ${characterString}, ${suffix}`;

    console.log(prompt);
    console.log(character);

    let resultData = await handleSendMessage("CharacterPortrait", prompt);
    console.log(resultData);
  };

  const handleEyeColorSelect = (color) => {
    setSelectedFeatures((prevFeatures) => ({
      ...prevFeatures,
      Eye_Color: [color.name],
    }));
    setEyeColorDialogOpen(false);
  };

  const handleHairColorSelect = (color) => {
    setSelectedFeatures((prevFeatures) => ({
      ...prevFeatures,
      Hair_Color: [color.name],
    }));
    setHairColorDialogOpen(false);
  };

  const randomizeEyeColors = () => {
    const shuffledColors = colors.sort(() => 0.5 - Math.random()).slice(0, 25);
    setRandomEyeColors(shuffledColors);
  };

  const randomizeHairColors = () => {
    const shuffledColors = colors.sort(() => 0.5 - Math.random()).slice(0, 25);
    setRandomHairColors(shuffledColors);
  };
   
return (
  <Container>
    <Typography variant="h4" gutterBottom>
      Character Creation
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Typography variant="h6" gutterBottom>
          Name
        </Typography>
        <Grid container spacing={1}>
          {availableNames.map((name) => (
            <Grid item xs={6} sm={4} key={name}>
              <Button
                fullWidth
                variant={selectedName === name ? "contained" : "outlined"}
                onClick={() => handleNameSelect(name)}
              >
                {name}
              </Button>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h6" gutterBottom style={{ marginTop: 20 }}>
          Gender
        </Typography>
        <Grid container spacing={1}>
          {availableGenders.map((gender) => (
            <Grid item xs={6} sm={4} key={gender}>
              <Button
                fullWidth
                variant={selectedGender === gender ? "contained" : "outlined"}
                onClick={() => handleGenderSelect(gender)}
              >
                {gender}
              </Button>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h5" gutterBottom style={{ marginTop: 20 }}>
          Race
        </Typography>
        <Grid container spacing={1}>
          {availableRaces.map((race) => (
            <Grid item xs={6} sm={4} key={race}>
              <Button
                fullWidth
                variant={selectedRace === race ? "contained" : "outlined"}
                onClick={() => handleRaceSelect(race)}
              >
                {race}
              </Button>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h5" gutterBottom style={{ marginTop: 20 }}>
          Character Class
        </Typography>
        <Grid container spacing={1}>
          {availableCharacterClasses.map((characterClass) => (
            <Grid item xs={6} sm={4} key={characterClass}>
              <Button
                fullWidth
                variant={
                  selectedCharacterClass === characterClass
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleCharacterClassSelect(characterClass)}
              >
                {characterClass}
              </Button>
            </Grid>
          ))}
        </Grid>

        <Typography variant="h5" gutterBottom style={{ marginTop: 20 }}>
          Facial Features
        </Typography>
        <Typography variant="h6" gutterBottom>
          Eye Color
        </Typography>
        <Button
          fullWidth
          variant={selectedFeatures.Eye_Color ? "contained" : "outlined"}
          onClick={() => setEyeColorDialogOpen(true)}
        >
          {selectedFeatures.Eye_Color
            ? selectedFeatures.Eye_Color[0]
            : "Select Eye Color"}
        </Button>

        {Object.keys(availableFeatures).map((category) => (
          <div key={category} style={{ marginTop: 20 }}>
            <Typography variant="h6" gutterBottom>
              {category.replace(/_/g, " ")}
            </Typography>
            <Grid container spacing={1}>
              {availableFeatures[category].map((feature) => (
                <Grid item xs={6} sm={4} key={feature}>
                  <Button
                    fullWidth
                    variant={
                      selectedFeatures[category]?.includes(feature)
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleFeatureSelect(category, feature)}
                  >
                    {feature.replace(/_/g, " ")}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </Grid>

      <Grid item xs={12} md={4}>
        <Box mt={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={randomizeOptions}
          >
            Randomize
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={clearSelections}
            style={{ marginTop: 10 }}
          >
            Clear
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="success"
            onClick={handleSubmit}
            disabled={loading}
            style={{ marginTop: 10 }}
          >
            Submit
          </Button>
        </Box>

        <Typography variant="h5" gutterBottom style={{ marginTop: 20 }}>
          Hair
        </Typography>
        <Typography variant="h6" gutterBottom>
          Hair Color
        </Typography>
        <Button
          fullWidth
          variant={selectedFeatures.Hair_Color ? "contained" : "outlined"}
          onClick={() => setHairColorDialogOpen(true)}
        >
          {selectedFeatures.Hair_Color
            ? selectedFeatures.Hair_Color[0]
            : "Select Hair Color"}
        </Button>

        {Object.keys(availableHair).map((category) => (
          <div key={category} style={{ marginTop: 20 }}>
            <Typography variant="h6" gutterBottom>
              {category.replace(/_/g, " ")}
            </Typography>
            <Grid container spacing={1}>
              {availableHair[category].map((feature) => (
                <Grid item xs={6} sm={4} key={feature}>
                  <Button
                    fullWidth
                    variant={
                      selectedHair[category]?.includes(feature)
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleHairSelect(category, feature)}
                  >
                    {feature.replace(/_/g, " ")}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}

        <Typography variant="h5" gutterBottom style={{ marginTop: 20 }}>
          Body
        </Typography>
        {Object.keys(availableBody).map((category) => (
          <div key={category} style={{ marginTop: 20 }}>
            <Typography variant="h6" gutterBottom>
              {category.replace(/_/g, " ")}
            </Typography>
            <Grid container spacing={1}>
              {availableBody[category].map((feature) => (
                <Grid item xs={6} sm={4} key={feature}>
                  <Button
                    fullWidth
                    variant={
                      selectedBody[category]?.includes(feature)
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleBodySelect(category, feature)}
                  >
                    {feature.replace(/_/g, " ")}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}

        <Typography variant="h5" gutterBottom style={{ marginTop: 20 }}>
          Voice
        </Typography>
        {Object.keys(availableVoice).map((category) => (
          <div key={category} style={{ marginTop: 20 }}>
            <Typography variant="h6" gutterBottom>
              {category.replace(/_/g, " ")}
            </Typography>
            <Grid container spacing={1}>
              {availableVoice[category].map((feature) => (
                <Grid item xs={6} sm={4} key={feature}>
                  <Button
                    fullWidth
                    variant={
                      selectedVoice[category]?.includes(feature)
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() => handleVoiceSelect(category, feature)}
                  >
                    {feature.replace(/_/g, " ")}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}

        <Typography variant="h5" gutterBottom style={{ marginTop: 20 }}>
          Miscellaneous
        </Typography>
        {Object.keys(availableMiscellaneous).map((category) => (
          <div key={category} style={{ marginTop: 20 }}>
            <Typography variant="h6" gutterBottom>
              {category.replace(/_/g, " ")}
            </Typography>
            <Grid container spacing={1}>
              {availableMiscellaneous[category].map((feature) => (
                <Grid item xs={6} sm={4} key={feature}>
                  <Button
                    fullWidth
                    variant={
                      selectedMiscellaneous[category]?.includes(feature)
                        ? "contained"
                        : "outlined"
                    }
                    onClick={() =>
                      handleMiscellaneousSelect(category, feature)
                    }
                  >
                    {feature.replace(/_/g, " ")}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </Grid>

      <Grid item xs={12} md={4}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            border: "1px solid black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : result ? (
            <JsonRenderer data={result} />
          ) : (
            <Typography variant="h6">
              Image will be displayed here
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>

    {/* Eye Color Dialog */}
    <Dialog
      open={eyeColorDialogOpen}
      onClose={() => setEyeColorDialogOpen(false)}
    >
      <DialogTitle>Eye Color</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {randomEyeColors.map((color) => (
            <Grid item xs={3} key={color.name}>
              <ColorBox
                color={color.hex}
                onClick={() => handleEyeColorSelect(color)}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={randomizeEyeColors}>Randomize Eye Colors</Button>
        <Button onClick={() => setEyeColorDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>

    {/* Hair Color Dialog */}
    <Dialog
      open={hairColorDialogOpen}
      onClose={() => setHairColorDialogOpen(false)}
    >
      <DialogTitle>Hair Color</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {randomHairColors.map((color) => (
            <Grid item xs={3} key={color.name}>
              <ColorBox
                color={color.hex}
                onClick={() => handleHairColorSelect(color)}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={randomizeHairColors}>Randomize Hair Colors</Button>
        <Button onClick={() => setHairColorDialogOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  </Container>
);
};

export default CharacterCreation;
