import {
  Container,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import JsonRenderer from "./JsonRenderer";
import { useAuth0 } from "@auth0/auth0-react";
import useGetCollection from "./useGetCollection";
import { getAwsUrl } from "./EnvHelper";

const DragonChatInterface = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedDataFlow, setSelectedDataFlow] = useState({
    id: "",
    name: "",
    type: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const {
    collectionData: flows,
    error: flowsError,
    isLoading: flowsIsLoading,
    // fetchCollection: refreshFlows,
  } = useGetCollection("/flows");

  const {
    collectionData: prompts,
    error: promptsError,
    isLoading: promptsIsLoading,
    // fetchCollection: refreshPrompts,
  } = useGetCollection("/prompts/withInputFlow");

  // Placeholder for dataDefaults array
  const dataDefaults = [];

  const handleSelectedDataFlowChange = (event) => {
    const selectedFlow = flows.find((flow) => flow.name === event.target.value);
    const selectedPrompt = prompts.find(
      (prompt) => prompt.name === event.target.value
    );

    if (selectedFlow) {
      setSelectedDataFlow({
        id: selectedFlow.id,
        name: selectedFlow.name,
        type: "flow",
      });
    } else if (selectedPrompt) {
      setSelectedDataFlow({
        id: selectedPrompt.id,
        name: selectedPrompt.name,
        type: "prompt",
      });
    } else {
      setSelectedDataFlow({ id: "", name: "", type: "" });
    }
  };

  const handleSendMessage = async () => {
    let payload = {};
    let payload_name = selectedDataFlow.name;
    let payload_input = "";

    if (message === "") {
      payload_input =
        dataDefaults[
          flows.findIndex((flow) => flow.name === selectedDataFlow.name)
        ];
    } else {
      payload_input = message;
    }

    if (selectedDataFlow.type === "flow") {
      payload = {
        flow_id: selectedDataFlow.id,
        name: payload_name,
        payload: {
          input: payload_input,
          history: chatHistory,
        },
      };
    } else if (selectedDataFlow.type === "prompt") {
      payload = {
        prompt_id: selectedDataFlow.id,
        name: payload_name,
        payload: {
          input: payload_input,
          history: chatHistory,
        },
      };
    }

    const newChatHistory = [
      ...chatHistory,
      { type: "USER", text: message, result: null },
    ];
    setChatHistory(newChatHistory);
    setMessage("");
    setLoading(true);

    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "read:current_user email profile",
      },
    });
    fetch(getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/coil/execute", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        let resp =
          data && data.output && typeof data.output === "string"
            ? data.output
            : "";

        //if data.output.history exists (type checks too), remove it from the response
        if (data.output && data.output.history) {
          delete data.output.history;
        }
        setError("");
        console.log(data);
        setChatHistory([
          ...newChatHistory,
          { type: selectedDataFlow.name, text: resp, result: data },
        ]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setError(error.toString());
        setChatHistory([
          ...newChatHistory,
          {
            type: selectedDataFlow.name,
            text: "ERROR",
            result: error.toString(),
          },
        ]);
        setLoading(false);
      });
  };

  const handleClearChat = () => {
    setChatHistory([]);
  };

  return (
    <Container>
      <AppBar position="sticky">
        <Toolbar>
          <Select
            name="data_flow"
            id="data_flow"
            value={selectedDataFlow.name}
            onChange={handleSelectedDataFlowChange}
          >
            <MenuItem value="" disabled>
              Select
            </MenuItem>
            {flows.map((flow, index) => (
              <MenuItem
                key={index}
                value={flow.name}
                style={{ whiteSpace: "normal" }}
              >
                {flow.name} (Flow)
              </MenuItem>
            ))}
            {prompts.map((prompt, index) => (
              <MenuItem
                key={index}
                value={prompt.name}
                style={{ whiteSpace: "normal" }}
              >
                {prompt.name} (Prompt)
              </MenuItem>
            ))}
          </Select>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <Button color="inherit" onClick={handleClearChat}>
            Clear
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          padding: 2,
          height: "70vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            padding: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <List sx={{ maxHeight: "100%", overflow: "auto" }}>
            {chatHistory.map((chat, index) => (
              <ListItem
                key={index}
                sx={{
                  justifyContent:
                    chat.type === "USER" ? "flex-end" : "flex-start",
                  width: "100%",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <ListItemText
                    primary={
                      <>
                        {chat.text && <Typography>{chat.text}</Typography>}
                        {!chat.text && chat.result && (
                          <Box sx={{ width: "100%", marginTop: 2 }}>
                            {typeof chat.result === "string" ? (
                              <Typography>{chat.result}</Typography>
                            ) : (
                              <Box sx={{ marginTop: 2 }}>
                                <JsonRenderer data={chat.result} />
                              </Box>
                            )}
                          </Box>
                        )}
                      </>
                    }
                    sx={{
                      textAlign: chat.type === "USER" ? "right" : "left",
                      backgroundColor:
                        chat.type === "USER" ? "#e0f7fa" : "#f1f1f1",
                      borderRadius: 1,
                      padding: 1,
                      maxWidth: "90%",
                      marginBottom: chat.result ? 0 : 0,
                    }}
                  />
                </Box>
              </ListItem>
            ))}
            {(loading || flowsIsLoading || promptsIsLoading) && (
              <ListItem sx={{ justifyContent: "center", width: "100%" }}>
                <CircularProgress />
              </ListItem>
            )}
          </List>
        </Box>
        {error && <Typography color="error">{error}</Typography>}
        {flowsError && <Typography color="error">{flowsError}</Typography>}
        {promptsError && <Typography color="error">{promptsError}</Typography>}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 2,
          borderTop: "1px solid #ccc",
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type a message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSendMessage}
          sx={{ marginLeft: 2 }}
        >
          Run
        </Button>
      </Box>
    </Container>
  );
};

export default DragonChatInterface;
