import React, { useState } from "react";
import {
  Container,
  Card,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  AlertTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getAwsUrl } from "./EnvHelper";
import JsonRenderer from "./JsonRenderer"; // Import the new JsonRenderer component
import { useAuth0 } from "@auth0/auth0-react";
import useGetCollection from "./useGetCollection";

function DragonCoilRuns() {
  const [text, setText] = useState("");
  const [selectedDataFlow, setSelectedDataFlow] = useState({
    id: "",
    name: "",
  });
  const [result, setResult] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRun, setSelectedRun] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const {
    collectionData: flows,
    error: flowsError,
    isLoading: flowsIsLoading,
    fetchCollection: refreshFlows,
  } = useGetCollection("/flows");

  const {
    collectionData: runs,
    error: runsError,
    isLoading: runsIsLoading,
    fetchCollection: refreshRuns,
  } = useGetCollection("/coil/runs");

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleSelectedDataFlowChange = (event) => {
    const selectedFlow = flows.find((flow) => flow.name === event.target.value);
    setSelectedDataFlow(selectedFlow || { id: "", name: "" });
  };

  const handleReset = (event) => {
    event.preventDefault();
    setText("");
    setSelectedDataFlow({ id: "", name: "" });
    setResult("");
    setError("");
    refreshFlows();
    refreshRuns();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    let payload = {};
    let payload_name = selectedDataFlow.name;
    let payload_input = "";

    if (text === "") {
      const defaultInput =
        flows.find((flow) => flow.name === selectedDataFlow.name)?.default ??
        "";
      setText(defaultInput);
      payload_input = defaultInput;
    } else {
      payload_input = text;
    }

    payload = {
      flow_id: selectedDataFlow.id,
      name: payload_name,
      payload: {
        input: payload_input,
      },
    };

    console.log("payload: " + JSON.stringify(payload));
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: "read:current_user email profile",
      },
    });

    fetch(getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/coil/execute", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setError(data.error);
          setResult("");
        } else {
          setError("");
          setResult(data.result);
          refreshRuns();
        }
      })
      .catch((error) => {
        setError(error.toString());
        setResult("");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleDataFlowsClick = (event) => {
    event.preventDefault();
    refreshFlows();
  };

  const handleSelectRun = (run) => {
    setSelectedRun(run);
  };

  const handleRun = async () => {
    if (selectedRun) {
      console.log("Running selected run with ID:", selectedRun.id);

      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      fetch(
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") +
          `/coil/runs/${selectedRun.id}/process`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setError(data.error);
            setResult("");
            refreshRuns();
          } else {
            setError("");
            setResult(data.result);
            refreshRuns();
          }
        })
        .catch((error) => {
          setError(error.toString());
          setResult("");
          refreshRuns();
        });
    }
  };

  const handleDelete = async (runId) => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      const response = await fetch(
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + `/coil/runs/${runId}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        refreshRuns();
      } else {
        const data = await response.json();
        setError(data.error);
      }
    } catch (error) {
      console.error("Error deleting run:", error);
      setError(error.toString());
    }
    setIsLoading(false);
  };

  return (
    <Container className="DragonCoilRuns">
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <div>
          <FormControl fullWidth>
            <InputLabel htmlFor="data_flow">Data Flow</InputLabel>
            <Select
              name="data_flow"
              id="data_flow"
              value={selectedDataFlow.name}
              onChange={handleSelectedDataFlowChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {flows.map((flow, index) => (
                <MenuItem key={index} value={flow.name}>
                  {flow.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button onClick={handleDataFlowsClick}>Data Flows</Button>
        </div>
        <div>
          <TextField
            label="Text"
            name="text"
            id="text"
            multiline
            rows={10}
            value={text}
            onChange={handleTextChange}
            fullWidth
          />
        </div>
        <div>
          <Button type="submit" disabled={isSubmitting}>
            Submit
          </Button>
          <Button type="reset">Clear</Button>
        </div>
      </form>
      {error && (
        <Alert severity="error" onClose={() => setError("")}>
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
      {flowsError && (
        <Alert severity="error" onClose={() => setError("")}>
          <AlertTitle>Error</AlertTitle>
          {flowsError}
        </Alert>
      )}
      {runsError && (
        <Alert severity="error" onClose={() => setError("")}>
          <AlertTitle>Error</AlertTitle>
          {runsError}
        </Alert>
      )}
      {runs
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) //descending order
        .map((run, index) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h6">
                {run.createdAt} - {run.status} - {run.createdBy} - {run.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Card
                onClick={() => handleSelectRun(run)}
                style={{
                  cursor: "pointer",
                  backgroundColor: selectedRun === run ? "#f0f0f0" : "#fff",
                }}
              >
                <CardContent>
                  <Typography>ID: {run.id}</Typography>
                  <Typography>Input: {run.payload?.input}</Typography>
                  <Typography>
                    Current State: {run.current_state?.name}
                  </Typography>
                  <JsonRenderer data={run.payload} />
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRun}
                  >
                    Run
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(run.id)}
                  >
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </AccordionDetails>
          </Accordion>
        ))}
      {(isLoading || runsIsLoading || flowsIsLoading) && (
        <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
          <CircularProgress />
          <Typography sx={{ marginLeft: 2 }}>Loading</Typography>
        </Box>
      )}
      {result && (
        <Box sx={{ marginTop: 2 }}>
          {typeof result === "string" ? (
            <Typography>{result}</Typography>
          ) : (
            <JsonRenderer data={result} />
          )}
        </Box>
      )}
    </Container>
  );
}

export default DragonCoilRuns;
