const steps = [
// Phase 1: Idea Generation
{
id: 0,
title: "Input Adventure Theme",
stateKey: "userInput",
renderConfig: {
type: "input",
label: "What would you like the adventure to be about?",
buttonText: "Generate Adventure Ideas",
},
nextAction: "apiCall",
prompt: (data) => `
You are an expert RPG adventure designer.
As a language model developed by OpenAI, you will generate 3 unique adventure ideas based on the user's input.
User Input: "${data.userInput}"

Constraints:

- Each idea must include a unique Title, Theme, and Setting.
- Titles should be engaging and relevant.
- Themes should be diverse and captivating.
- Settings should be imaginative and detailed.
- Do not include any disallowed content.

Instructions:

- Provide the output in valid JSON format.
- Structure the JSON as an array of objects, each containing "Title", "Theme", and "Setting" keys.

Example Output Format:

[
{
"Title": "...",
"Theme": "...",
"Setting": "..."
},
...
]

Now, generate the 3 adventure ideas.
`,
resultKey: "adventureIdeas",
nextStep: 1,
},
{
id: 1,
title: "Select Adventure Idea",
stateKey: "selectedIdea",
renderConfig: {
type: "selection",
dataKey: "adventureIdeas",
itemTitleKey: "Title",
itemSubtitleKey: "Theme",
itemDescriptionKey: "Setting",
},
nextStep: 2,
},
// Phase 2: Antagonist Development
{
id: 2,
title: "Generate Antagonists",
stateKey: "antagonists",
renderConfig: {
type: "button",
buttonText: "Generate Antagonists",
},
nextAction: "apiCall",
prompt: (data) => `

You are an expert RPG adventure designer, specializing in creating compelling antagonists that enhance the narrative depth of the adventure.

**Context for Adventure:**

- **Original User Input:** "${data.userInput}"


- **Selected Adventure Idea:**

- **Title:** "${data.selectedIdea.Title}"

- **Theme:** "${data.selectedIdea.Theme}"

- **Setting:** "${data.selectedIdea.Setting}"

**Constraints for Antagonists:**

- Each antagonist must align with the adventure's theme and setting.

- They should have clear motivations that drive their actions.

- Provide details on their **Name**, **Basic Description**, **Initial Motivation**, **Role in Adventure**, and **Unique Abilities or Resources**.

- Ensure each antagonist is unique and memorable.

- Do not include any disallowed content.

---

**Instructions:**

- Provide the output in valid JSON format.

- Structure the JSON as an array of objects with keys: "Name", "BasicDescription", "InitialMotivation", "RoleInAdventure", and "AbilitiesAndResources".

---

Now, generate the antagonists.

`,

resultKey: "antagonists",

nextStep: 3,

},

{

id: 3,

title: "Select Antagonist",

stateKey: "selectedAntagonist",

renderConfig: {

type: "selection",

dataKey: "antagonists",

itemTitleKey: "Name",

itemSubtitleKey: "BasicDescription",

itemDescriptionKey: "RoleInAdventure",

},

nextStep: 4,

},

{

id: 4,

title: "Generate Antagonist Details",

stateKey: "antagonistDetails",

renderConfig: {

type: "button",

buttonText: "Generate Antagonist Details",

},

nextAction: "apiCall",

prompt: (data) => `

You are an expert RPG adventure designer.

You will now create an in-depth profile for the selected antagonist to ensure they drive the narrative effectively.

**Selected Antagonist:**

${JSON.stringify(data.selectedAntagonist, null, 2)}

**Adventure Context:**

- **Original User Input:** "${data.userInput}"

- **Selected Adventure Idea:**

- **Title:** "${data.selectedIdea.Title}"

- **Theme:** "${data.selectedIdea.Theme}"

- **Setting:** "${data.selectedIdea.Setting}"

**Constraints:**

- Expand upon the antagonist's profile with the following details:

- "Name": Confirm the antagonist's name.

- "Description": Detailed physical appearance and characteristics.

- "Backstory": Comprehensive history and background.

- "Motivation": Deep dive into their goals and what drives them.

- "Weaknesses": Known vulnerabilities or flaws.

- "RoleInAdventure": Their specific role and impact on the plot.

- "EvolutionOverTime": How the antagonist changes throughout the adventure.

- Ensure consistency with the adventure's theme and setting.

- Do not include any disallowed content.

---

**Instructions:**

- Provide the output in valid JSON format.

- Structure the JSON as:

{

"Name": "...",

"Description": "...",

"Backstory": "...",

"Motivation": "...",

"Weaknesses": "...",

"RoleInAdventure": "...",

"EvolutionOverTime": "..."

}

---

Now, generate the antagonist details.

`,

resultKey: "antagonistDetails",

nextStep: 5,

},

// Phase 3: Adventure Module Construction

{

id: 5,

title: "Generate Adventure Overview",

stateKey: "adventureOverview",

renderConfig: {

type: "button",

buttonText: "Generate Adventure Overview",

},

nextAction: "apiCall",

prompt: (data) => `

You are an expert RPG adventure designer.

You will now create the "Title" and "Overview" sections for the adventure module.

Original User Input: "${data.userInput}"

Selected Adventure Idea:

- Title: "${data.selectedIdea.Title}"

- Theme: "${data.selectedIdea.Theme}"

- Setting: "${data.selectedIdea.Setting}"

Selected Antagonist:

${JSON.stringify(data.antagonistDetails, null, 2)}

Constraints:

- Provide the Title of the adventure module.

- Write a compelling Overview of the adventure, including intended player levels, setting, and main objectives.

- Identify the core Themes that the adventure explores and the Central Conflicts that drive the story forward.

- Include at least one Moral Dilemma that players may face during the adventure.

- Provide a couple of Contextual Examples that highlight how themes and conflicts manifest in the adventure.

- Ensure all content is consistent with the selected Theme, Setting, and Antagonist.

- Do not include any disallowed content.

Instructions:

- Provide the output in valid JSON format.

- Structure the JSON as:

{

"Title": "...",

"AdventureModule": {

"Overview": "...",

"ThemesAndConflicts": {

"Themes": [

"Theme 1: Description...",

"Theme 2: Description..."

],

"CentralConflicts": [

"Conflict 1: Description...",

"Conflict 2: Description..."

],

"MoralDilemmas": [

"Dilemma 1: Description...",

"Dilemma 2: Description..."

],

"ContextualExamples": [

"Example 1: Description...",

"Example 2: Description..."

]

}

}

}

Now, generate the Adventure Overview.

`,

resultKey: "adventureOverview",

nextStep: 6,

},

{

id: 6,

title: "Generate Adventure Summary",

stateKey: "adventureSummary",

renderConfig: {

type: "button",

buttonText: "Generate Adventure Summary",

},

nextAction: "apiCall",

prompt: (data) => `

You are an expert RPG adventure designer.

Original User Input: "${data.userInput}"

Selected Adventure Idea:

- Title: "${data.selectedIdea.Title}"

- Theme: "${data.selectedIdea.Theme}"

- Setting: "${data.selectedIdea.Setting}"

Selected Antagonist:

${JSON.stringify(data.antagonistDetails, null, 2)}

You will now create the "AdventureSummary" section for the adventure module.

Previously Generated Sections:

- Adventure Overview:

${JSON.stringify(data.adventureOverview, null, 2)}

Constraints:

- Provide a concise summary of the plot, outlining the main events and goals.

- Ensure consistency with the Title, Overview, Selected Adventure Idea, and Selected Antagonist.

- Do not include any disallowed content.

Instructions:

- Update the existing JSON by adding the "AdventureSummary" key to the "AdventureModule" object.

Example Output Format:

{

"AdventureModule": {

"AdventureSummary": "..."

}

}

Now, write the Adventure Summary.

`,

resultKey: "adventureSummary",

nextStep: 7,

},

{

id: 7,

title: "Generate Dramatis Personae (Cast List)",

stateKey: "dramatisPersonae",

renderConfig: {

type: "button",

buttonText: "Generate Cast List",

},

nextAction: "apiCall",

prompt: (data) => `

You are an expert RPG adventure designer tasked with developing a vibrant and dynamic cast of characters.

**Original User Input:** "${data.userInput}"

**Selected Adventure Idea:**

- Title: "${data.selectedIdea.Title}"

- Theme: "${data.selectedIdea.Theme}"

- Setting: "${data.selectedIdea.Setting}"

**Selected Antagonist:**

${JSON.stringify(data.antagonistDetails, null, 2)}

**Previously Generated Sections:**

- Adventure Overview:

${JSON.stringify(data.adventureOverview, null, 2)}

- Adventure Summary:

${JSON.stringify(data.adventureSummary, null, 2)}

**Objective:**

Populate the **Dramatis Personae** section with the major characters (NPCs) who will play significant roles in the adventure, offering Game Masters essential information to bring these characters to life.

**Instructions:**

1. **Antagonist Recap:**

- Reiterate the details of the antagonist, ensuring consistency with the previous sections.

- Expand on their description slightly to reinforce their importance in the narrative.

2. **Major NPCs:**

- List all major NPCs that players will encounter throughout the adventure.

- Each NPC should have a distinct personality, role, and motivation.

3. **Details for Each NPC:**

- **Name:** The NPC's full name.

- **Description:** Physical appearance, demeanor, and notable traits.

- **Role in Adventure:** How they impact the plot and interact with the players.

- **Backstory:** A brief history explaining their motivations.

- **Motivation:** What drives this NPC, and what do they seek to achieve?

- **Relationships:** How do they relate to other NPCs or the antagonist?

- **Unique Traits or Quirks:** Highlight any behaviors that make the NPC stand out.

- **Role-Playing Notes:** Tips for how the Game Master can portray the NPC.

4. **Integration Tips:**

- Suggest ways in which the NPC can be woven into different parts of the adventure. This could include dialogue cues, potential alliances or conflicts with the players, and how their story unfolds as the adventure progresses.

**Example Output Format:**

{

"AdventureModule": {

"DramatisPersonae": {

"Antagonist": {

"Name": "Kaelith the Forsaken",

"BasicDescription": "A twisted former protector of the elemental realms, Kaelith seeks to bend the planes to his will."

...

},

"MajorNPCs": [

{

"Name": "Elder Sareth",

"Description": "A tall, gaunt figure with a flowing silver beard, once a mentor to the antagonist.",

"RoleInAdventure": "Sareth offers guidance to the players but hides crucial information about Kaelith’s fall.",

"Backstory": "Sareth trained Kaelith in the ways of elemental magic before Kaelith’s lust for power drove him to betrayal.",

"Motivation": "Sareth is driven by guilt and hopes to redeem himself by helping the players stop Kaelith.",

"Relationships": "Once a mentor to Kaelith, now a reluctant ally to the players.",

"UniqueTraitsOrQuirks": "Sareth often speaks in riddles, forcing players to think critically.",

"RolePlayingNotes": "Portray him as soft-spoken but burdened by deep regret."

},

...

]

}

}

}

Now, write the Dramatis Personae.

`,

resultKey: "dramatisPersonae",

nextStep: 8,

},

{

id: 8,

title: "Generate Adventure Hooks",

stateKey: "adventureHooks",

renderConfig: {

type: "button",

buttonText: "Generate Adventure Hooks",

},

nextAction: "apiCall",

prompt: (data) => `

You are an expert RPG adventure designer.

Original User Input: "${data.userInput}"

Selected Adventure Idea:

- Title: "${data.selectedIdea.Title}"

- Theme: "${data.selectedIdea.Theme}"

- Setting: "${data.selectedIdea.Setting}"

Selected Antagonist:

${JSON.stringify(data.antagonistDetails, null, 2)}

You will now create the "AdventureHooks" section for the adventure module.

**Previously Generated Sections:**

- Adventure Overview:

${JSON.stringify(data.adventureOverview, null, 2)}

- Adventure Summary:

${JSON.stringify(data.adventureSummary, null, 2)}

- Dramatis Personae:

${JSON.stringify(data.dramatisPersonae, null, 2)}

**Constraints:**

- Provide a list of hooks or reasons to involve the players in the adventure.

- Include ways to integrate player backgrounds into the hooks.

- Ensure hooks are engaging and relevant to the adventure.

- Do not include any disallowed content.

**Instructions:**

- Update the existing JSON by adding the "AdventureHooks" key to the "AdventureModule" object.

**Example Output Format:**

{

"AdventureModule": {

"AdventureHooks": [

"Hook 1...",

"Hook 2...",

...

]

}

}

Now, write the Adventure Hooks.

`,

resultKey: "adventureHooks",

nextStep: 9,

},

{

id: 9,

title: "Generate Lore",

stateKey: "adventureLore",

renderConfig: {

type: "button",

buttonText: "Generate Adventure Lore",

},

nextAction: "apiCall",

prompt: (data) => `

You are an expert RPG adventure designer.

Original User Input: "${data.userInput}"

Selected Adventure Idea:

- Title: "${data.selectedIdea.Title}"

- Theme: "${data.selectedIdea.Theme}"

- Setting: "${data.selectedIdea.Setting}"

Selected Antagonist:

${JSON.stringify(data.antagonistDetails, null, 2)}

You will now create the "Lore" section for the adventure module.

**Previously Generated Sections:**

- Adventure Overview:

${JSON.stringify(data.adventureOverview, null, 2)}

- Adventure Summary:

${JSON.stringify(data.adventureSummary, null, 2)}

- Adventure Hooks: ${JSON.stringify(data.adventureHooks.AdventureModule.AdventureHooks, null, 2)}

**Constraints:**

- Provide detailed lore for the adventure, including:

- "HistoricalBackground": Detailed history relevant to the adventure.

- "MythsAndLegends": Local myths or legends that tie into the story.

- "CulturalPractices": Unique traditions or customs of the setting.

- Ensure the lore is consistent with the earlier sections and enriches the adventure.

**Instructions:**

- Update the existing JSON by adding the "Lore" key to the "AdventureModule" object.

**Example Output Format:**

{

"AdventureModule": {

"Lore": {

"HistoricalBackground": "...",

"MythsAndLegends": "...",

"CulturalPractices": "..."

}

}

}

Now, write the Lore section.

`,

resultKey: "adventureLore",

nextStep: 10,

},

{

id: 10,

title: "Generate Adventure Parts Outline",

stateKey: "adventurePartsOutline",

renderConfig: {

type: "button",

buttonText: "Generate Adventure Parts Outline",

},

nextAction: "apiCall",

prompt: (data) => `

You are a seasoned RPG adventure designer tasked with crafting an engaging and cohesive adventure outline.

Your objective is to structure the adventure into distinct, logical parts that guide the narrative from beginning to end.

**Previously Generated Sections:**

- Adventure Overview:

${JSON.stringify(data.adventureOverview, null, 2)}

- Adventure Summary: ${JSON.stringify(data.adventureSummary, null, 2)}

- Adventure Hooks: ${JSON.stringify(data.adventureHooks, null, 2)}

- Lore: ${JSON.stringify(data.adventureLore, null, 2)}

**Constraints:**

- Divide the adventure into a series of parts (e.g., Part 1, Part 2, Part 3) that collectively form a coherent story arc.

- For each part, include:

- "PartTitle": A captivating title for this part of the adventure.

- "BriefDescription": A short description that outlines the key events and objectives of this part.

**Instructions:**

- Integrate the newly created "Parts" array into the "AdventureModule" object within the existing JSON structure.

**Example Output Format:**

{

"AdventureModule": {

"Parts": [

{

"PartTitle": "...",

"BriefDescription": "..."

},

...

]

}

}

Now, generate the adventure parts outline.

`,

resultKey: "adventurePartsOutline",

nextStep: 11,

},

{

id: 11,

title: "Generate Side Quests and Optional Encounters",

stateKey: "sideQuestsAndEncounters",

renderConfig: {

type: "button",

buttonText: "Generate Side Quests & Encounters",

},

nextAction: "apiCall",

prompt: (data) => `

You are an expert RPG adventure designer tasked with creating engaging and immersive side quests and optional encounters for a dynamic adventure module.

Your goal is to enrich the main storyline with additional content that enhances player experience and provides opportunities for character development.

Each side quest and encounter should be unique, memorable, and seamlessly integrate with the main narrative. Ensure that they offer meaningful choices and challenges, and can be adapted to various RPG systems.

**Adventure Context:**

- **Original User Input:** "${data.userInput}"

- **Selected Adventure Idea:** "${data.selectedIdea.Title}"

- **Theme:** "${data.selectedIdea.Theme}"

- **Setting:** "${data.selectedIdea.Setting}"

- **Antagonist:** "${data.antagonistDetails.Name}"

**Previously Generated Sections:**

- Adventure Overview:

${JSON.stringify(data.adventureOverview, null, 2)}

- Adventure Summary:

${JSON.stringify(data.adventureSummary, null, 2)}

- Lore:

${JSON.stringify(data.adventureLore, null, 2)}

- Adventure Parts Outline:

${JSON.stringify(data.adventurePartsOutline, null, 2)}

**Constraints:**

- For each side quest, provide:

- "Title": A captivating title.

- "Description": Outlining the quest's objectives and challenges.

- "ConnectionToMainStory": How it ties into the main narrative.

- For each optional encounter, provide:

- "Title": A descriptive title.

- "Description": Vivid account of the encounter's setting and events.

- "Suggestions": Creative ways the encounter can unfold.

- "AdaptationTips": Tips to tailor the encounter to different RPG systems.

- Ensure all content is relevant and enhances the main adventure.

- Do not include any disallowed content.

---

**Instructions:**

- Provide the output in valid JSON format.

- Structure the JSON as:

{

"AdventureModule": {

"SideQuestsAndOptionalContent": {

"SideQuests": [

{

"Title": "...",

"Description": "...",

"ConnectionToMainStory": "..."

},

...

],

"Encounters": [

{

"Title": "...",

"Description": "...",

"Suggestions": "...",

"AdaptationTips": "..."

},

...

]

}

}

}

---

Now, write the Side Quests and Optional Encounters.

`,

resultKey: "sideQuestsAndEncounters",

nextStep: 12,

},

// Phase 4: Detailed Content Creation

{

id: 12,

title: "Generate Detailed Content for Each Part",

stateKey: "adventureParts",

renderConfig: {

type: "button",

buttonText: "Generate Detailed Parts",

},

nextAction: "apiCallMultiple",

prompt: (data) =>

data.adventurePartsOutline.AdventureModule.Parts.map((part, index) => ({

PartTitle: part.PartTitle,

prompt: `

You are an expert RPG adventure designer.

You will now create detailed content for the following part of the adventure.

**Part Information:**

${JSON.stringify(part, null, 2)}

**Adventure Context:**

- **Original User Input:** "${data.userInput}"

- **Selected Adventure Idea:** "${data.selectedIdea.Title}"

- **Theme:** "${data.selectedIdea.Theme}"

- **Setting:** "${data.selectedIdea.Setting}"

- **Antagonist:** "${data.antagonistDetails.Name}"

**Previously Generated Sections:**

- Adventure Overview:

${JSON.stringify(data.adventureOverview, null, 2)}

- Adventure Summary:

${JSON.stringify(data.adventureSummary, null, 2)}

- Lore:

${JSON.stringify(data.adventureLore, null, 2)}

- Side Quests and Encounters:

${JSON.stringify(data.sideQuestsAndEncounters, null, 2)}

- Dramatis Personae:

${JSON.stringify(data.dramatisPersonae, null, 2)}

**Constraints:**

- For this part, include:

- "PartTitle": Title of this part.

- "ReadAloudText": Narrative text for the Game Master (GM) to read aloud to set the scene for the players.

- "GMNotes": Specific guidance and important information for the GM about this section.

- "KeyNPCs": Detailed information about important NPCs in this part.

- "Locations": Detailed description of key locations in this part.

- "Events": Significant events that occur in this part.

- "MoralDilemmas": Any moral or ethical choices presented to the players.

- "Rewards": Rewards that players can earn in this part.

- "IntegratedLore": How the lore is integrated into this part.

- "UniqueMechanics": Any unique game mechanics introduced in this part.

- Follow the structure provided in the adventure module template.

**Instructions:**

- Provide the output in valid JSON format.

- Structure the JSON as:

{

"Part": {

"PartTitle": "...",

"ReadAloudText": "...",

"GMNotes": "...",

"KeyNPCs": [

{

"Name": "...",

"Role": "...",

"Interactions": "..."

},

...

],

"Locations": [

{

"Name": "...",

"Description": "...",

"Significance": "..."

},

...

],

"Events": [

{

"EventTitle": "...",

"Description": "...",

"Challenges": "...",

"MoralDilemmas": "..."

},

...

],

"Rewards": [

{

"ItemName": "...",

"Description": "...",

"Symbolism": "...",

"Properties": "..."

},

...

],

"IntegratedLore": "...",

"UniqueMechanics": {

"MechanicsDescription": "...",

"ImplementationSuggestions": "..."

}

}

}

---

Now, generate the detailed content for this part.

`,

})),

resultKey: "adventureParts",

nextStep: 13,

},

{

id: 13,

title: "Generate Conclusion",

stateKey: "adventureConclusion",

renderConfig: {

type: "button",

buttonText: "Generate Conclusion",

},

nextAction: "apiCall",

prompt: (data) => `

You are an expert RPG adventure designer.

You will now create the "Conclusion" section for the adventure module.

**Adventure Context:**

- **Original User Input:** "${data.userInput}"

- **Selected Adventure Idea:** "${data.selectedIdea.Title}"

- "- **Theme:** "${data.selectedIdea.Theme}"

- **Setting:** "${data.selectedIdea.Setting}"

- **Antagonist:** "${data.antagonistDetails.Name}"

**Previously Generated Sections:**

- Adventure Summary:

${JSON.stringify(data.adventureSummary, null, 2)}

- Adventure Parts:

${JSON.stringify(data.adventureParts, null, 2)}

- Side Quests and Encounters:

${JSON.stringify(data.sideQuestsAndEncounters, null, 2)}

- Dramatis Personae:

${JSON.stringify(data.dramatisPersonae, null, 2)}

**Constraints:**

- Provide a satisfying conclusion to the adventure, including:

- "ReadAloudText": Narrative text to conclude the adventure, summarizing the outcome.

- "PossibleEndings": Multiple endings based on player choices, each with:

- "EndingTitle": Title or brief description of the ending.

- "Description": Detailed description of this possible ending based on player choices.

- "Outcomes": Specific outcomes for major NPCs, changes to the setting, potential future plot hooks.

- "GMNotes": Guidance on wrapping up the story, addressing unresolved elements, and introducing future adventure possibilities.

**Instructions:**

- Update the existing JSON by adding the "Conclusion" key to the "AdventureModule" object.

**Example Output Format:**

{

"AdventureModule": {

"Conclusion": {

"ReadAloudText": "...",

"PossibleEndings": [

{

"EndingTitle": "...",

"Description": "...",

"Outcomes": ["...", "..."]

},

...

],

"GMNotes": "..."

}

}

}

Now, write the Conclusion section.

`,

resultKey: "adventureConclusion",

nextStep: 14,

},

{

id: 14,

title: "Generate Visual Aids",

stateKey: "visualAids",

renderConfig: {

type: "button",

buttonText: "Generate Visual Aids",

},

nextAction: "apiCall",

prompt: (data) => `

You are an expert RPG adventure designer tasked with supplementing the adventure with visual elements to aid the GM in storytelling and presentation.

**Adventure Context:**

- **Original User Input:** "${data.userInput}"

- **Selected Adventure Idea:** "${data.selectedIdea.Title}"

- "- **Theme:** "${data.selectedIdea.Theme}"

- **Setting:** "${data.selectedIdea.Setting}"

- **Antagonist:** "${data.antagonistDetails.Name}"

**Previously Generated Sections:**

- Adventure Overview:

${JSON.stringify(data.adventureOverview, null, 2)}

- Adventure Summary:

${JSON.stringify(data.adventureSummary, null, 2)}

- Dramatis Personae:

${JSON.stringify(data.dramatisPersonae, null, 2)}

- Adventure Hooks:

${JSON.stringify(data.adventureHooks, null, 2)}

- Lore:

${JSON.stringify(data.adventureLore, null, 2)}

- Adventure Parts:

${JSON.stringify(data.adventureParts, null, 2)}

- Side Quests and Encounters:

${JSON.stringify(data.sideQuestsAndEncounters, null, 2)}

- Conclusion:

${JSON.stringify(data.adventureConclusion, null, 2)}

**Constraints:**

- Provide visual aids to enhance the adventure experience, including:

- "Maps": Detailed maps relevant to key locations.

- "Diagrams": Schematics or diagrams that explain important elements.

- "Illustrations": Artistic representations of characters, locations, or significant events.


- For each visual aid, include:

- "Title": A descriptive title.

- "Description": What the visual aid depicts.

- "Reference": Placeholder for URL or instructions on where to obtain/create the visual aid.

- Ensure that visual aids are relevant and aid in the storytelling process.

- Do not include any disallowed content.

---

**Instructions:**

- Provide the output in valid JSON format.

- Structure the JSON as:

{

"AdventureModule": {

"VisualAids": {

"Maps": [

{

"Title": "...",

"Description": "...",

"Reference": "..."

},

...

],

"Diagrams": [

{

"Title": "...",

"Description": "...",

"Reference": "..."

},

...

],

"Illustrations": [

{

"Title": "...",

"Description": "...",

"Reference": "..."

},

...

]

}

}

}

---

Now, write the Visual Aids section.

`,

resultKey: "visualAids",

nextStep: 16,

},
// Phase 5: Choose-Your-Own-Adventure Story Generation

{

id: 16,

title: "Generate Choose-Your-Own-Adventure Story",

stateKey: "chooseYourOwnAdventure",

renderConfig: {

type: "button",

buttonText: "Generate Adventure Story",

},

nextAction: "apiCallMultiple",

prompt: (data) => {

// Assuming that the adventure module is fully built by step 15 and available as data.adventureModule

const adventureModule = data.adventureModule;

// Define the number of chapters based on adventure parts

const chapters = adventureModule.Parts || [];

// Map each part to a chapter prompt

return chapters.map((chapter, index) => ({

ChapterNumber: index + 1,

prompt: `

You are a skilled author tasked with transforming an intricate RPG adventure module into an engaging Choose-Your-Own-Adventure (CYOA) story. Each chapter should be detailed, expanding to at least 10 pages in a novel format, and should closely follow the narrative arc and structure outlined in the adventure module. Incorporate multiple decision points throughout each chapter to allow readers to influence the outcome of the story based on their choices.

**Adventure Context:**

- **Title:** "${adventureModule.Title}"

- **Overview:** "${adventureModule.Overview}"

- **Adventure Summary:** "${adventureModule.AdventureSummary}"

- **Antagonist Details:** ${JSON.stringify(adventureModule.DramatisPersonae.Antagonist, null, 2)}

- **Major NPCs:** ${JSON.stringify(adventureModule.DramatisPersonae.MajorNPCs, null, 2)}

- **Lore:** ${JSON.stringify(adventureModule.Lore, null, 2)}

- **Adventure Hooks:** ${JSON.stringify(adventureModule.AdventureHooks.AdventureHooks, null, 2)}

- **Side Quests and Encounters:** ${JSON.stringify(adventureModule.SideQuestsAndOptionalContent, null, 2)}

- **Conclusion:** ${JSON.stringify(adventureModule.Conclusion, null, 2)}

- **Visual Aids:** ${JSON.stringify(adventureModule.VisualAids, null, 2)}

**Chapter Information:**

- **Chapter Title:** "${chapter.PartTitle}"

- **Brief Description:** "${chapter.BriefDescription}"

**Constraints:**

- Each chapter should be at least 10 pages in length, written in a novelistic style.

- Follow the structure and narrative provided in the adventure module.

- Incorporate multiple decision points in each chapter where readers can choose different paths.

- Decision points should lead to meaningful changes in the story’s outcome.

- Maintain consistency with character motivations, lore, and the overarching plot.

- Ensure that each chapter seamlessly transitions to the next based on reader choices.

- Do not include any disallowed content.

**Instructions:**

- Output each chapter in valid JSON format.

- Structure the JSON as:

{

"ChapterNumber": X,

"ChapterTitle": "...",

"Content": "..." // The full text of the chapter with decision points

}

**Example Output Format:**

{

"ChapterNumber": 1,

"ChapterTitle": "The Whispering Forest",

"Content": "..."

}

---

Now, generate Chapter ${index + 1}: "${chapter.PartTitle}" of the Choose-Your-Own-Adventure story.

`,

}));

},

resultKey: "chooseYourOwnAdventure",

nextStep: 17,

},

// Phase 6: Final Integration and Review

{

id: 17,

title: "Compile CYOA Story",

stateKey: "compiledCYOAStory",

renderConfig: {

type: "button",

buttonText: "Compile CYOA Story",

},

nextAction: "apiCall",

prompt: (data) => `

You have generated multiple chapters for a Choose-Your-Own-Adventure (CYOA) story based on the adventure module.

**Adventure Context:**

- **Title:** "${data.adventureModule.Title}"

**Generated Chapters:**

${JSON.stringify(data.chooseYourOwnAdventure, null, 2)}

**Objective:**

Compile all generated chapters into a cohesive Choose-Your-Own-Adventure story. Ensure consistent formatting, smooth transitions between chapters, and proper integration of decision points that guide the reader seamlessly through different story paths.

**Constraints:**

- Maintain a clear and engaging narrative style throughout.

- Ensure that all decision points are well-integrated and lead to logical outcomes.

- Maintain consistency in character behaviors, settings, and plot developments across all chapters.

- Avoid redundancy and ensure that each chapter uniquely contributes to the overall story progression.

- Do not include any disallowed content.

**Instructions:**

- Provide the output in valid JSON format.

- Structure the JSON as:

{

"ChooseYourOwnAdventure": {

"Title": "...",

"Description": "...",

"Chapters": [

{

"ChapterNumber": X,

"ChapterTitle": "...",

"Content": "..."

},

...

]

}

}

**Example Output Format:**

{

"ChooseYourOwnAdventure": {

"Title": "The Quest for the Lost Artifact",

"Description": "An epic journey through mysterious lands where your choices determine the fate of the realm.",

"Chapters": [

{

"ChapterNumber": 1,

"ChapterTitle": "The Whispering Forest",

"Content": "..."

},

...

]

}

}

---

Now, compile the Choose-Your-Own-Adventure story.

`,

resultKey: "compiledCYOAStory",

nextStep: 18,

},

{

id: 18,

title: "Final Review and Export",

stateKey: "finalReview",

renderConfig: {

type: "final",

dataKey: "compiledCYOAStory",

label: "Your Choose-Your-Own-Adventure story has been successfully generated!",

},

// No nextStep as it's the end of the process

},

];

export default steps;
