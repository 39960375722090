import React, { useState } from "react";
import {
  Container,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Paper,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { getAwsUrl } from "./EnvHelper";
import { useAuth0 } from "@auth0/auth0-react";
import useGetCollection from "./useGetCollection";

function DragonFlows() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newFlow, setNewFlow] = useState({
    name: "",
    payload: {},
    userFacing: false,
  });
  const [tempPayload, setTempPayload] = useState(""); // Temporary state for JSON input
  const [jsonError, setJsonError] = useState(""); // State for JSON validation error
  const { getAccessTokenSilently } = useAuth0();

  const {
    collectionData: flows,
    error: flowsError,
    isLoading: flowsIsLoading,
    fetchCollection: refreshFlows,
  } = useGetCollection("/flows");

  const handleCreateFlow = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      await fetch(getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/flows", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newFlow),
      });
      refreshFlows();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error creating flow:", error);
    }
    setIsLoading(false);
  };

  const handleSelectFlow = (flow) => {
    setSelectedFlow(flow);
    setTempPayload(JSON.stringify(flow.payload, null, 2)); // Initialize tempPayload
    setJsonError(""); // Reset JSON error
  };

  const handleUpdateFlow = async () => {
    if (jsonError) {
      alert("Please fix the JSON error before updating.");
      return;
    }

    setIsLoading(true);
    try {
      const updatedFlow = { ...selectedFlow, payload: JSON.parse(tempPayload) };

      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      await fetch(
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") +
          `/flows/${selectedFlow.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedFlow),
        }
      );
      refreshFlows();
    } catch (error) {
      console.error("Error updating flow:", error);
    }
    setIsLoading(false);
  };

  return (
    <Container className="DragonFlows">
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        Create New Flow
      </Button>
      <List component={Paper}>
        {flows.map((flow, index) => (
          <ListItem
            key={index}
            button
            onClick={() => handleSelectFlow(flow)}
            selected={selectedFlow === flow}
          >
            <ListItemText primary={flow.name} />
          </ListItem>
        ))}
      </List>
      <div className="flows-main">
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <DialogTitle>Create New Flow</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Flow Name"
              fullWidth
              value={newFlow.name}
              onChange={(e) => setNewFlow({ ...newFlow, name: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Payload JSON"
              fullWidth
              multiline
              rows={10} // Increase the number of rows
              value={JSON.stringify(newFlow.payload, null, 2)}
              onChange={(e) =>
                setNewFlow({ ...newFlow, payload: JSON.parse(e.target.value) })
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newFlow.userFacing}
                  onChange={(e) =>
                    setNewFlow({ ...newFlow, userFacing: e.target.checked })
                  }
                />
              }
              label="User Facing"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreateFlow} color="primary">
              Create
            </Button>
            <Button onClick={() => setIsModalOpen(false)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {selectedFlow && (
          <Paper
            className="flow-details"
            style={{ padding: "16px", marginTop: "16px" }}
          >
            <Typography variant="h6">Edit Flow</Typography>
            <TextField
              margin="dense"
              label="Flow Name"
              fullWidth
              value={selectedFlow.name}
              onChange={(e) =>
                setSelectedFlow({ ...selectedFlow, name: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Payload JSON"
              fullWidth
              multiline
              rows={10} // Increase the number of rows
              value={tempPayload}
              onChange={(e) => {
                setTempPayload(e.target.value);
                try {
                  JSON.parse(e.target.value);
                  setJsonError(""); // Clear error if JSON is valid
                } catch (error) {
                  setJsonError("Invalid JSON format");
                }
              }}
            />
            {jsonError && (
              <Typography color="error" variant="body2">
                {jsonError}
              </Typography>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedFlow.userFacing}
                  onChange={(e) =>
                    setSelectedFlow({
                      ...selectedFlow,
                      userFacing: e.target.checked,
                    })
                  }
                />
              }
              label="User Facing"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateFlow}
              disabled={!!jsonError} // Disable the button if there's a JSON error
            >
              Update
            </Button>
          </Paper>
        )}
      </div>
      {(isLoading || flowsIsLoading) && (
        <div>
          <CircularProgress />
          <Typography>Loading</Typography>
        </div>
      )}
      {flowsError && <Typography color="error">{flowsError}</Typography>}
    </Container>
  );
}

export default DragonFlows;
