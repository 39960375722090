import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  Box,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const Playground = () => {
  const [model, setModel] = useState("gpt-4o-2024-08-06");
  const [temperature, setTemperature] = useState(0.6);
  const [topP, setTopP] = useState(0.6);
  const [formatJson, setFormatJson] = useState(false);
  const [role, setRole] = useState("user");
  const [prompt, setPrompt] = useState("");
  const defaultChatHistory = [
    {
      role: "system",
      content:
        "You are a helpful AI. Please dissect and examine each conversation or text thoroughly and deliver a detailed, step-by-step response. Prioritize accuracy by individually verifying each element and ensure the final answer is correct by meticulously reconfirming all facts. Do not document your thought process and validation steps.",
    },
  ];
  const [chatHistory, setChatHistory] = useState(defaultChatHistory);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");

  const handleRun = async () => {
    setLoading(true);
    try {
      const updatedChatHistory = [
        ...chatHistory,
        { role: "user", content: prompt },
      ];
      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer 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`, // Move to scales call if this works
          },
          body: JSON.stringify({
            model,
            messages: updatedChatHistory,
            temperature,
            top_p: topP,
            ...(formatJson && { format: { type: "json_object" } }),
          }),
        }
      );
      const data = await response.json();
      setResponse(data.choices[0].message.content);
      setChatHistory([
        ...updatedChatHistory,
        {
          role: "assistant",
          content: data.choices[0].message.content,
        },
      ]);
    } catch (error) {
      console.error("Error generating response:", error);
    } finally {
      setLoading(false);
      setPrompt(""); // Clear the prompt after sending
    }
  };

  const handleAddMessage = () => {
    setChatHistory([...chatHistory, { role, content: prompt }]);
    setPrompt("");
  };

  const handleClearMessages = () => {
    setChatHistory(defaultChatHistory);
    setResponse("");
  };

  const handleDeleteMessage = (index) => {
    setChatHistory(chatHistory.filter((_, i) => i !== index));
  };

  return (
    <Container maxWidth="sm" style={{ padding: "20px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <FormControl fullWidth margin="normal">
          <InputLabel>Role</InputLabel>
          <Select value={role} onChange={(e) => setRole(e.target.value)}>
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="assistant">Assistant</MenuItem>
            {/* Add more roles as needed, <MenuItem value="system">System</MenuItem> */}
          </Select>
        </FormControl>
        <IconButton color="primary" onClick={handleAddMessage}>
          <AddIcon />
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClearMessages}
        >
          Clear
        </Button>
      </Box>

      <TextField
        fullWidth
        margin="normal"
        label="Prompt"
        multiline
        rows={4}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />

      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleRun}
        disabled={loading}
      >
        {loading ? "Running..." : "Run"}
      </Button>

      <Box marginTop="20px">
        <h3>Chat History</h3>
        {chatHistory.map((message, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            marginBottom="10px"
          >
            <Box flexGrow={1}>
              <strong>{message.role}:</strong> {message.content}
            </Box>
            {message.role !== "system" && (
              <IconButton
                color="secondary"
                onClick={() => handleDeleteMessage(index)}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        ))}
      </Box>

      {response && (
        <Box marginTop="20px">
          <h3>Response</h3>
          <Box marginBottom="10px">
            <strong>Assistant:</strong> {response}
          </Box>
        </Box>
      )}

      <Box display="none">
        <FormControl fullWidth margin="normal">
          <InputLabel>Model</InputLabel>
          <Select value={model} onChange={(e) => setModel(e.target.value)}>
            <MenuItem value="gpt-4o-2024-08-06">GPT-4o</MenuItem>
            <MenuItem value="gpt-3.5-turbo">GPT-3.5 Turbo</MenuItem>
            <MenuItem value="gpt-3.5">GPT-3.5</MenuItem>
            {/* Add more models as needed */}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          margin="normal"
          label="Temperature"
          type="number"
          value={temperature}
          onChange={(e) => setTemperature(parseFloat(e.target.value))}
          inputProps={{ step: 0.1, min: 0, max: 2 }}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Top P"
          type="number"
          value={topP}
          onChange={(e) => setTopP(parseFloat(e.target.value))}
          inputProps={{ step: 0.1, min: 0, max: 1 }}
        />

        <FormControlLabel
          control={
            <Switch
              checked={formatJson}
              onChange={(e) => setFormatJson(e.target.checked)}
            />
          }
          label="Format JSON"
        />
      </Box>
    </Container>
  );
};

export default Playground;
