import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getAwsUrl } from "./EnvHelper";

const useGetCollection = (url) => {
  const { getAccessTokenSilently } = useAuth0();
  const [collectionData, setCollectionData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCollection = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      const response = await fetch(
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + url,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (data.status === "success") {
        setCollectionData(data.data);
        setError(null);
      } else {
        setError(data.message);
        setCollectionData([]);
      }
    } catch (error) {
      setError(error.toString());
    }
    setIsLoading(false);
  }, [getAccessTokenSilently, url]);

  useEffect(() => {
    fetchCollection();
  }, [fetchCollection]);

  return { collectionData, error, isLoading, fetchCollection };
};

export default useGetCollection;
