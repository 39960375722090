const AWS_REGION = process.env.AWS_REGION || "us-east-1";

/// deriveUrlFromId returns the API Gateway URL based on the ID of the API Gateway
function deriveUrlFromId(id) {
  return "https://" + id + ".execute-api.***.amazonaws.com/prod";
}

/// getEnvStartsWith returns the value of the environment variable that starts with the prefix
function getEnvStartsWith(prefix) {
  const envKeys = Object.keys(process.env);
  const foundKey = envKeys.find((key) => key.startsWith(prefix));

  // If the key is not found, check for the default values
  if (!foundKey) {
    console.log("ENV: Using local default values");
    if (prefix === "REACT_APP_DRAGONCDKSTACK_ENDPOINT") {
      return deriveUrlFromId("cmtpbv0xsk");
    }
  }

  console.log("ENV: Using the CDK values");
  return foundKey ? process.env[foundKey] : undefined;
}

/// getAwsUrl returns the URL based on the prefix
function getAwsUrl(prefix) {
  const url = getEnvStartsWith(prefix);
  return url ? url.replace("***", AWS_REGION) : "";
}

export { getAwsUrl };
