import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Paper,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import useSendMessage from "./useSendMessage"; // Import the sendMessage hook
import steps from "./useAdventureSteps";

const AdventureCreator = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [stepData, setStepData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedParentIndex, setSelectedParentIndex] = useState(0);
  const [selectedChildIndex, setSelectedChildIndex] = useState(0);
  const [sceneLoadingStatus, setSceneLoadingStatus] = useState({}); // For iterative loading

  const { handleSendMessage } = useSendMessage();

  const getStepIndexById = (id) => {
    return steps.findIndex((step) => step.id === id);
  };

  const apiCall = async (prompt) => {
    try {
      const response = await handleSendMessage("Plain", prompt);

      // Remove code block formatting if present
      if (response.text.startsWith("```json")) {
        response.text = response.text.replace("```json", "").replace("```", "");
      }

      return response.text;
    } catch (error) {
      console.error("Error in apiCall:", error);
      throw error;
    }
  };

  const handleNext = async () => {
    const currentStep = steps[activeStep];
    if (currentStep.nextAction) {
      setLoading(true);
      try {
        let result;
        if (currentStep.nextAction === "apiCall") {
          const promptText = currentStep.prompt(stepData, currentStep);
          const response = await apiCall(promptText);
          result = JSON.parse(response);
          console.log(`Result for ${currentStep.resultKey}:`, result);
          setStepData((prevData) => ({
            ...prevData,
            [currentStep.resultKey]: result,
          }));

          if (currentStep.nextStep !== undefined) {
            setActiveStep(getStepIndexById(currentStep.nextStep));
          }
          setLoading(false);
        } else if (currentStep.nextAction === "apiCallMultiple") {
          const prompts = currentStep.prompt(stepData, currentStep);
          const results = {};
          await Promise.all(
            prompts.map(async (itemPrompt) => {
              const itemNumber = itemPrompt[currentStep.itemNumberKey];
              try {
                const response = await apiCall(itemPrompt.prompt);
                results[itemNumber] = JSON.parse(response);
                console.log(
                  `Breakdown for ${currentStep.itemKey} ${itemNumber}:`,
                  results[itemNumber]
                );
              } catch (error) {
                console.error(
                  `Error processing ${currentStep.itemKey} ${itemNumber}:`,
                  error
                );
              }
            })
          );

          setStepData((prevData) => ({
            ...prevData,
            [currentStep.resultKey]: results,
          }));

          if (currentStep.nextStep !== undefined) {
            setActiveStep(getStepIndexById(currentStep.nextStep));
          }
          setLoading(false);
        } else if (currentStep.nextAction === "apiCallNested") {
          const prompts = currentStep.prompt(stepData, currentStep);
          const loadingStatus = {};
          // Initialize loading status
          prompts.forEach((item) => {
            const parentItemNumber = item[currentStep.parentItemNumberKey];
            const childItemNumber = item[currentStep.itemNumberKey];
            if (!loadingStatus[parentItemNumber]) {
              loadingStatus[parentItemNumber] = {};
            }
            loadingStatus[parentItemNumber][childItemNumber] = true;
          });
          setSceneLoadingStatus(loadingStatus);
          // Process prompts asynchronously
          await Promise.all(
            prompts.map(async (item) => {
              const parentItemNumber = item[currentStep.parentItemNumberKey];
              const childItemNumber = item[currentStep.itemNumberKey];
              try {
                const response = await apiCall(item.prompt);
                setStepData((prevData) => {
                  const existingData = prevData[currentStep.resultKey] || {};
                  const actData = existingData[parentItemNumber] || {};
                  actData[childItemNumber] = response;
                  existingData[parentItemNumber] = actData;
                  return {
                    ...prevData,
                    [currentStep.resultKey]: existingData,
                  };
                });
              } catch (error) {
                console.error(
                  `Error processing ${currentStep.itemKey} ${childItemNumber} in ${currentStep.parentItemKey} ${parentItemNumber}:`,
                  error
                );
              } finally {
                setSceneLoadingStatus((prevStatus) => {
                  const updatedStatus = { ...prevStatus };
                  updatedStatus[parentItemNumber][childItemNumber] = false;
                  return updatedStatus;
                });
              }
            })
          );

          setLoading(false);
          if (currentStep.nextStep !== undefined) {
            setActiveStep(getStepIndexById(currentStep.nextStep));
          }
        }
      } catch (error) {
        console.error("Error during API call:", error);
        // Handle error appropriately
        setLoading(false);
      }
    } else if (currentStep.nextStep !== undefined) {
      setActiveStep(getStepIndexById(currentStep.nextStep));
    }
  };

  const handleInputChange = (e) => {
    const currentStep = steps[activeStep];
    setStepData({
      ...stepData,
      [currentStep.stateKey]: e.target.value,
    });
  };

  const handleSelection = (item) => {
    const currentStep = steps[activeStep];
    setStepData({
      ...stepData,
      [currentStep.stateKey]: item,
    });
    if (currentStep.nextStep !== undefined) {
      setActiveStep(getStepIndexById(currentStep.nextStep));
    }
  };

  const handleRestart = () => {
    setActiveStep(0);
    setStepData({});
    setSelectedParentIndex(0);
    setSelectedChildIndex(0);
    setSceneLoadingStatus({});
  };

  const renderStepContent = () => {
    const currentStep = steps[activeStep];
    const { renderConfig } = currentStep;

    switch (renderConfig.type) {
      case "input":
        return (
          <div>
            <Typography variant="h6">{currentStep.title}</Typography>
            <TextField
              label={renderConfig.label}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              value={stepData[currentStep.stateKey] || ""}
              onChange={handleInputChange}
              style={{ marginTop: "16px" }}
            />
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={
                loading || !(stepData[currentStep.stateKey] || "").trim()
              }
              style={{ marginTop: "16px" }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                renderConfig.buttonText
              )}
            </Button>
          </div>
        );
      case "selection":
        const items = stepData[renderConfig.dataKey] || [];
        return (
          <div>
            <Typography variant="h6">{currentStep.title}</Typography>
            <Grid container spacing={2}>
              {items.map((item, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5">
                        {item[renderConfig.itemTitleKey]}
                      </Typography>
                      <Typography color="textSecondary">
                        {renderConfig.itemSubtitleKey === "*"
                          ? JSON.stringify(item, null, 2)
                          : item[renderConfig.itemSubtitleKey]}
                      </Typography>
                      <Typography variant="body2">
                        {renderConfig.itemDescriptionKey === "*"
                          ? JSON.stringify(item, null, 2)
                          : item[renderConfig.itemDescriptionKey]}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => handleSelection(item)}
                      >
                        Select
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Button
              onClick={() => setActiveStep(activeStep - 1)}
              style={{ marginTop: "16px" }}
            >
              Back
            </Button>
          </div>
        );
      case "button":
        return (
          <div>
            <Typography variant="h6">{currentStep.title}</Typography>
            <Button variant="contained" onClick={handleNext} disabled={loading}>
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                renderConfig.buttonText
              )}
            </Button>
            <div>
              <Button
                onClick={() => setActiveStep(activeStep - 1)}
                style={{ marginTop: "16px" }}
              >
                Back
              </Button>
            </div>
          </div>
        );
      case "display":
        return (
          <div>
            <Typography variant="h6">{currentStep.title}</Typography>
            <pre style={{ whiteSpace: "pre-wrap" }}>
              {JSON.stringify(stepData[renderConfig.dataKey] || {}, null, 2)}
            </pre>
            <Button variant="contained" onClick={handleNext} disabled={loading}>
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                renderConfig.buttonText
              )}
            </Button>
            <div>
              <Button
                onClick={() => setActiveStep(activeStep - 1)}
                style={{ marginTop: "16px" }}
              >
                Back
              </Button>
            </div>
          </div>
        );
      case "tabs":
        const dataKey = renderConfig.dataKey; // 'sceneBreakdowns' or 'detailedScenes'
        const data = stepData[dataKey]; // Data for current step

        const parentItemNumberKey = renderConfig.parentItemNumberKey;
        const childItemNumberKey = renderConfig.childItemNumberKey;
        const parentItemKey = renderConfig.parentItemKey;
        const childItemKey = renderConfig.childItemKey;
        const childCollectionKey = renderConfig.childCollectionKey;

        let acts = [];

        if (data) {
          acts = Object.keys(data).map((actNumber) => {
            const actData = data[actNumber];
            return {
              [parentItemNumberKey]: actNumber,
              ...actData,
            };
          });
        }

        return (
          <div>
            <Typography variant="h6">{currentStep.title}</Typography>
            <Tabs
              value={selectedParentIndex}
              onChange={(event, newValue) => {
                setSelectedParentIndex(newValue);
                setSelectedChildIndex(0);
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              style={{ marginBottom: "16px" }}
            >
              {acts.map((act, index) => (
                <Tab
                  label={`${parentItemKey} ${act[parentItemNumberKey]}`}
                  key={index}
                />
              ))}
            </Tabs>
            {acts.map((act, index) => (
              <div
                key={index}
                role="tabpanel"
                hidden={selectedParentIndex !== index}
              >
                {selectedParentIndex === index && (
                  <div>
                    {(() => {
                      let scenes = [];

                      if (dataKey === "detailedScenes") {
                        // For detailedScenes, scenes are stored differently
                        const actNumber = act[parentItemNumberKey];
                        const actData = data[actNumber];
                        scenes = Object.keys(actData || {}).map(
                          (sceneNumber) => ({
                            [childItemNumberKey]: sceneNumber,
                            content: actData[sceneNumber],
                          })
                        );
                      } else {
                        // For sceneBreakdowns, scenes are in act[childCollectionKey]
                        scenes = act[childCollectionKey] || [];
                      }

                      if (!scenes.length) {
                        return (
                          <Typography variant="body2">
                            {sceneLoadingStatus &&
                            sceneLoadingStatus[act[parentItemNumberKey]] &&
                            Object.values(
                              sceneLoadingStatus[act[parentItemNumberKey]]
                            ).some((status) => status === true)
                              ? "Loading scenes..."
                              : "No scenes available."}
                          </Typography>
                        );
                      }

                      return (
                        <>
                          <Tabs
                            value={selectedChildIndex}
                            onChange={(event, newValue) =>
                              setSelectedChildIndex(newValue)
                            }
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            style={{ marginBottom: "16px" }}
                          >
                            {scenes.map((scene, sIndex) => (
                              <Tab
                                label={`${childItemKey} ${scene[childItemNumberKey]}`}
                                key={sIndex}
                              />
                            ))}
                          </Tabs>
                          {scenes.map((scene, sIndex) => (
                            <div
                              key={sIndex}
                              role="tabpanel"
                              hidden={selectedChildIndex !== sIndex}
                            >
                              {selectedChildIndex === sIndex && (
                                <Paper
                                  style={{ padding: "16px", marginTop: "16px" }}
                                >
                                  {dataKey === "detailedScenes" ? (
                                    scene.content ? (
                                      <Typography
                                        variant="body2"
                                        style={{ whiteSpace: "pre-wrap" }}
                                      >
                                        {scene.content}
                                      </Typography>
                                    ) : sceneLoadingStatus &&
                                      sceneLoadingStatus[
                                        act[parentItemNumberKey]
                                      ] &&
                                      sceneLoadingStatus[
                                        act[parentItemNumberKey]
                                      ][scene[childItemNumberKey]] ? (
                                      <div style={{ textAlign: "center" }}>
                                        <CircularProgress size={24} />
                                        <Typography variant="body2">
                                          Loading detailed scene...
                                        </Typography>
                                      </div>
                                    ) : (
                                      <Typography variant="body2">
                                        Detailed content not available.
                                      </Typography>
                                    )
                                  ) : (
                                    <pre style={{ whiteSpace: "pre-wrap" }}>
                                      {JSON.stringify(scene, null, 2)}
                                    </pre>
                                  )}
                                </Paper>
                              )}
                            </div>
                          ))}
                        </>
                      );
                    })()}
                  </div>
                )}
              </div>
            ))}
            {!stepData.detailedScenes && !loading && (
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  renderConfig.buttonText
                )}
              </Button>
            )}
            <div>
              <Button
                onClick={() => setActiveStep(activeStep - 1)}
                style={{ marginTop: "16px" }}
              >
                Back
              </Button>
            </div>
          </div>
        );
      case "final":
        return (
          <div>
            <Typography variant="h6">{currentStep.title}</Typography>
            <pre style={{ whiteSpace: "pre-wrap" }}>
              {JSON.stringify(stepData || {}, null, 2)}
            </pre>
            <Button
              variant="contained"
              onClick={handleRestart}
              style={{ marginTop: "16px" }}
            >
              Restart
            </Button>
            <div>
              <Button
                onClick={() => setActiveStep(activeStep - 1)}
                style={{ marginTop: "16px" }}
              >
                Back
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: "32px" }}>
      <Typography variant="h4" gutterBottom>
        RPG Adventure Creator
      </Typography>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        style={{ marginBottom: "32px" }}
      >
        {steps.map((step, index) => (
          <Step key={index} completed={activeStep > index}>
            <StepLabel onClick={() => setActiveStep(index)}>
              {step.title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderStepContent()}
    </Container>
  );
};

export default AdventureCreator;
