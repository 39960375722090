import React, { useState } from "react";
import {
  Container,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemText,
  Paper,
  Checkbox,
  FormControlLabel,
  ListItemButton,
} from "@mui/material";
import { getAwsUrl } from "./EnvHelper";
import { useAuth0 } from "@auth0/auth0-react";
import useGetCollection from "./useGetCollection";

function DragonPrompts() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPrompt, setNewPrompt] = useState({
    name: "",
    payload: "Write a haiku.",
    inputFlow: false,
  });
  const { getAccessTokenSilently } = useAuth0();
  const {
    collectionData: prompts,
    error,
    isLoading: promptsIsLoading,
    fetchCollection: refreshPrompts,
  } = useGetCollection("/prompts");

  const handleCreatePrompt = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      await fetch(getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/prompts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newPrompt),
      });
      refreshPrompts();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error creating prompt:", error);
    }
    setIsLoading(false);
  };

  const handleSelectPrompt = (prompt) => {
    setSelectedPrompt(prompt);
  };

  const handleDeletePrompt = (id) => async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      await fetch(
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + `/prompts/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      refreshPrompts();
    } catch (error) {
      console.error("Error deleting prompt:", error);
    }
    setIsLoading(false);
  };

  const handleUpdatePrompt = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      await fetch(
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") +
          `/prompts/${selectedPrompt.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(selectedPrompt),
        }
      );
      refreshPrompts();
    } catch (error) {
      console.error("Error updating prompt:", error);
    }
    setIsLoading(false);
  };

  return (
    <Container className="DragonPrompts">
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        Create New Prompt
      </Button>
      <List component={Paper}>
        {prompts.map((prompt, index) => (
          <ListItemButton
            key={index}
            onClick={() => handleSelectPrompt(prompt)}
            selected={selectedPrompt === prompt}
          >
            <ListItemText primary={prompt.name} />

            <Button
              variant="contained"
              color="secondary"
              onClick={handleDeletePrompt(prompt.id)}
            >
              Delete
            </Button>
          </ListItemButton>
        ))}
      </List>
      <div className="prompts-main">
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <DialogTitle>Create New Prompt</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Prompt Name"
              fullWidth
              value={newPrompt.name}
              onChange={(e) =>
                setNewPrompt({ ...newPrompt, name: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Payload"
              fullWidth
              multiline
              rows={4}
              value={newPrompt.payload}
              onChange={(e) =>
                setNewPrompt({ ...newPrompt, payload: e.target.value })
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newPrompt.inputFlow}
                  onChange={(e) =>
                    setNewPrompt({ ...newPrompt, inputFlow: e.target.checked })
                  }
                />
              }
              label="Input Flow"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreatePrompt} color="primary">
              Create
            </Button>
            <Button onClick={() => setIsModalOpen(false)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {selectedPrompt && (
          <Paper className="prompt-details">
            <Typography variant="h6">Edit Prompt</Typography>
            <TextField
              margin="dense"
              label="Prompt ID"
              fullWidth
              value={selectedPrompt.id}
              onChange={(e) =>
                setSelectedPrompt({ ...selectedPrompt, name: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Prompt Name"
              fullWidth
              value={selectedPrompt.name}
              onChange={(e) =>
                setSelectedPrompt({ ...selectedPrompt, name: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Payload"
              fullWidth
              multiline
              rows={4}
              value={selectedPrompt.payload}
              onChange={(e) =>
                setSelectedPrompt({
                  ...selectedPrompt,
                  payload: e.target.value,
                })
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedPrompt.inputFlow}
                  onChange={(e) =>
                    setSelectedPrompt({
                      ...selectedPrompt,
                      inputFlow: e.target.checked,
                    })
                  }
                />
              }
              label="Input Flow"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdatePrompt}
            >
              Update
            </Button>
          </Paper>
        )}
      </div>
      {(isLoading || promptsIsLoading) && (
        <div>
          <CircularProgress />
          <Typography>Loading</Typography>
        </div>
      )}
      {error && <Typography color="error">{error}</Typography>}
    </Container>
  );
}

export default DragonPrompts;
