/* eslint-disable no-unused-vars */
import { useState } from "react";
import { getAwsUrl } from "./EnvHelper";
import { useAuth0 } from "@auth0/auth0-react";
import useGetCollection from "./useGetCollection";

const useSendMessage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [result, setResult] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  const {
    collectionData: flows,
    error: flowsError,
    isLoading: flowsIsLoading,
  } = useGetCollection("/flows");

  const handleSendMessage = async (
    flowName,
    message = "",
    chatHistory = []
  ) => {
    console.log("handleSendMessage called with:", {
      flowName,
      message,
      chatHistory,
    });

    console.log("Flows:", flows);
    const selectedFlow = flows.find((flow) => flow.name === flowName);
    if (!selectedFlow) {
      console.warn(`Flow with name ${flowName} not found`);
      return;
    }

    const payload = {
      flow_id: selectedFlow.id,
      name: flowName,
      payload: {
        input: message,
        history: chatHistory,
      },
    };

    console.log("Payload prepared:", payload);

    setLoading(true);
    setError("");
    setResult(null);

    let returnData = null;

    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      console.log("Access token obtained");

      const response = await fetch(
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/coil/execute",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      console.log("Response received:", data);

      let resp =
        data && data.output && typeof data.output === "string"
          ? data.output
          : "";

      if (data.output && data.output.history) {
        delete data.output.history;
      }

      setResult(data);
      setError("");
      console.log("Processed response:", data);

      setLoading(false);
      returnData = { type: flowName, text: resp, result: data };
    } catch (error) {
      console.error("Error occurred:", error);
      setError(error.toString());
      setResult(null);

      setLoading(false);
      returnData = {
        type: flowName,
        text: "ERROR",
        result: error.toString(),
      };
    } finally {
      console.log("handleSendMessage completed with returnData:", returnData);
      return returnData;
    }
  };

  return {
    handleSendMessage,
    loading,
    error,
    result,
    flows,
    flowsError,
    flowsIsLoading,
  };
};

export default useSendMessage;
