import React, { useCallback, useEffect } from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  MiniMap,
  Controls,
  Background,
  addEdge,
  useNodesState,
  useEdgesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import StateNode from "./flowNodes/StateNode";

const generateNodes = (states, initialState, onChange) => {
  const initialStateIndex = states.findIndex(
    (state) => state.name === initialState
  );
  const initialNode = {
    id: states[initialStateIndex].name,
    data: { onChange: onChange, name: states[initialStateIndex].name },
    position: { x: 0, y: 0 },
    type: "stateNode",
    style: { border: "1px solid #000", background: "#afa" },
  };

  const otherNodes = states
    .filter((_, index) => index !== initialStateIndex)
    .map((state, index) => ({
      id: state.name,
      data: { onChange: onChange, name: state.name },
      position: { x: 100 * (index + 1), y: 100 * (index + 1) },
      type: "stateNode",
      style: { border: "1px solid #000", background: "#afa" },
    }));

  return [initialNode, ...otherNodes];
};

const generateEdges = (states) => {
  return states.flatMap((state) =>
    state.transitions.map((transition) => ({
      id: `e-${state.name}-${transition.target_state}`,
      source: state.name,
      target: transition.target_state,
      animated: true,
    }))
  );
};

const FlowDiagram = ({ flowJson }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const onConnect = useCallback(
    (connection) => setEdges((edges) => addEdge(connection, edges)),
    [setEdges]
  );

  const nodeTypes = {
    stateNode: StateNode,
  };

  useEffect(() => {
    const onChange = (event) => {
      setNodes((nds) =>
        nds.map((node) => {
          const name = event.target.value;

          return {
            ...node,
            data: {
              ...node.data,
              name,
            },
          };
        })
      );
    };

    const nodes = generateNodes(
      flowJson.states,
      flowJson.initial_state,
      onChange
    );
    const edges = generateEdges(flowJson.states);

    console.log("nodes", nodes);
    console.log("edges", edges);

    setNodes(nodes);
    setEdges(edges);
  }, [flowJson, setEdges, setNodes]);

  const onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView();
  };

  return (
    <ReactFlowProvider>
      <div style={{ height: 500 }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          nodeTypes={nodeTypes}
          onConnect={onConnect}
          onLoad={onLoad}
          style={{ width: "100%", height: "100%" }}
        >
          <MiniMap />
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </div>
    </ReactFlowProvider>
  );
};

export default FlowDiagram;
