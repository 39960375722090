import React, { useState, useEffect, useCallback } from "react";
import {
  Container,
  Button,
  CircularProgress,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import { getAwsUrl } from "./EnvHelper";
import { useAuth0 } from "@auth0/auth0-react";

function Admin() {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    email: "",
    roles: [],
  });
  const { getAccessTokenSilently } = useAuth0();
  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      console.log("token", token);
      const response = await fetch(
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/admin/user_roles",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );

      if (!response.ok) {
        // Log the status and status text
        console.error("HTTP error", response.status, response.statusText);
        // Read and log the response body
        const text = await response.text();
        console.log("Response body:", text);
        throw new Error("Unauthorized");
      }

      const data = await response.json();
      setUsers(Array.isArray(data) ? data : []); // Ensure users is an array
    } catch (error) {
      console.error("Error fetching users:", error);
      setUsers([]); // Set users to an empty array in case of error
    }
    setIsLoading(false);
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleCreateUser = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      await fetch(
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") + "/admin/user_roles",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newUser),
        }
      );
      fetchUsers();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error creating user:", error);
    }
    setIsLoading(false);
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  const handleUpdateUser = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user email profile",
        },
      });
      await fetch(
        getAwsUrl("REACT_APP_DRAGONSCALES_ENDPOINT") +
          `/admin/user_roles/${selectedUser.email}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(selectedUser),
        }
      );
      fetchUsers();
    } catch (error) {
      console.error("Error updating user:", error);
    }
    setIsLoading(false);
  };

  return (
    <Container className="Admin">
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsModalOpen(true)}
      >
        Create New User Role
      </Button>
      <List component={Paper}>
        {Array.isArray(users) &&
          users.map((user, index) => (
            <ListItem
              key={index}
              button
              onClick={() => handleSelectUser(user)}
              selected={selectedUser === user}
            >
              <ListItemText primary={user.email} />
            </ListItem>
          ))}
      </List>
      <div className="users-main">
        <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <DialogTitle>Create New User Role</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Email"
              fullWidth
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Roles (comma separated)"
              fullWidth
              value={newUser.roles.join(", ")}
              onChange={(e) =>
                setNewUser({ ...newUser, roles: e.target.value.split(", ") })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreateUser} color="primary">
              Create
            </Button>
            <Button onClick={() => setIsModalOpen(false)} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {selectedUser && (
          <Paper className="user-details">
            <Typography variant="h6">Edit User Role</Typography>
            <TextField
              margin="dense"
              label="Email"
              fullWidth
              value={selectedUser.email}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, email: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Roles (comma separated)"
              fullWidth
              value={selectedUser.roles.join(", ")}
              onChange={(e) =>
                setSelectedUser({
                  ...selectedUser,
                  roles: e.target.value.split(", "),
                })
              }
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateUser}
            >
              Update
            </Button>
          </Paper>
        )}
      </div>
      {isLoading && (
        <div>
          <CircularProgress />
          <Typography>Loading</Typography>
        </div>
      )}
    </Container>
  );
}

export default Admin;
